import {Box, Button, IconButton, Paper} from "@material-ui/core";
import TilBackDrop from "../TilBackDrop/TilBackDrop";
import {AttachFile, Clear} from "@material-ui/icons";
import {useEffect, useState} from "react";
import {API_URL, IMG_URL} from "../../config/constants";
import axios from "axios";
import LinearWithValueLabel from "../LinearProgressWithLabel/LinearProgressWithLabel";

function TilUploadComponent(
    {
        field,
        multiple,
        onCloseClicked,
        objectID,
        tableName,
        service,
        fileField,
        rows,
        displayInTable,
        onDisplayInTableFileUploaded,
        type = "image"
    }) {
    const [uploadedImages, setUploadedImages] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadItemsNumber, setUploadItemsNumber] = useState(0);
    const [filesWillDelete, setFilesWillDelete] = useState([]);

    /**
     *
     *
     * listeners
     */

    useEffect(() => {
        console.log(uploadItemsNumber, uploadProgress)
        if (uploadItemsNumber > 0 && (uploadItemsNumber - filesWillDelete.length)=== uploadProgress)
            onCloseClicked()
    }, [uploadProgress, uploadItemsNumber])

    useEffect(() => {
        if (multiple)
            service.getRowFiles(objectID, tableName)
                .then(res => {
                    console.log(res)
                    let uploadableImagesWU = [];
                    res.forEach(file => {
                        uploadableImagesWU.push({
                            label: file.image,
                            src: IMG_URL + file.image,
                        })
                    })
                    setUploadedImages(uploadableImagesWU)
                })
        else {
            const file = rows.find(row => row.id === objectID)[fileField];
            let uploadableImagesWU = [];
            if (file !== '')
                uploadableImagesWU[0] = {
                    label: file,
                    src: IMG_URL + file,
                    // file
                }
            setUploadedImages(uploadableImagesWU)
            console.log(uploadableImagesWU)
        }
    }, [multiple, fileField, rows]);


    const handleUploaded = file => {
        let uploadableImagesWU = [...uploadedImages];
        setUploadItemsNumber(uploadItemsNumber + Array.from(file.target.files).length)
        Array.from(file.target.files).forEach(file => {
            if (multiple)
                uploadableImagesWU.unshift({
                    label: file.name,
                    src: URL.createObjectURL(file),
                    file
                })
            else
                uploadableImagesWU[0] = {
                    label: file.name,
                    src: URL.createObjectURL(file),
                    file
                }
        })
        setUploadedImages(uploadableImagesWU)
    }

    const onValidateClicked = async () => {
        const dbTableName = multiple ? `${tableName}_${field}` : tableName;
        const queryType = multiple ? 'insert' : 'update';
        if (multiple) {
            let index = 0;
            while (index < filesWillDelete.length) {
                const resDelete = await fetch(`${API_URL}delete-images-from-db&objectID=${objectID}&tableName=${tableName}_images&fileName='${filesWillDelete[index].fileName}'`)
                    .then(res => res.json())
                    .catch(err => console.info(err));
                if (resDelete) {
                    index++;
                } else break;
            }

        } else {
            if (uploadedImages.length === 0) {
                fetch(API_URL + `reset-object-image&tableName=${tableName}&field=${field}&objectID=${objectID}`)
                    .then(res => res.json())
                    // .then(res => console.log('res reset image: ', res))
                    .catch(err => err)
                return;
            }
        }
        uploadedImages.filter(ui => filesWillDelete.findIndex(fwd => fwd.fileName === ui.file ? ui.file.name : ui.label) === -1).forEach(image => {
            let formData = new FormData();
            formData.append('file', image.file);
            axios.post(API_URL + `file-upload&objectID=${objectID}&tableName=${dbTableName}&queryType=${queryType}&field=${field}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    method: 'POST'
                })

                .then(res => {
                    if (res.data === true) {
                        setUploadProgress((prevProgress) => (prevProgress + 1));
                        // if (displayInTable) {
                            const url = "product-" + objectID + "-" + image.file.name;
                            onDisplayInTableFileUploaded(field, url, objectID)
                        // }
                    }


                })
                .catch(err => console.log(err))
        })
    }

    const removeImage = label => {
        setUploadedImages(uploadedImages.filter(image => image.label !== label));
        setFilesWillDelete(filesWillDelete.concat([{fileName: label}]))
    }


    if (objectID === 0)
        return (<TilBackDrop open={true}><Paper><Box padding={2}>Chargement...</Box></Paper></TilBackDrop>)
    return (
        <TilBackDrop open={true}>
            <Paper style={{width: '70%', padding: 16}}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} my={1}>
                    <h3 style={{minWidth: 'fit-content'}}>Upload {field}</h3>
                    <LinearWithValueLabel nbItems={uploadItemsNumber - filesWillDelete.length}
                                          parentProgress={uploadProgress}/>
                    <Button color={'primary'} variant={'contained'} component={'label'}>
                        <AttachFile/>
                        <input id={'k'} type="file" hidden multiple={multiple} onChange={e => handleUploaded(e)}/>
                        {/*<label htmlFor={'k'}>+</label>*/}


                    </Button>
                </Box>
                <Box padding={1} overflow={'auto'} display={'flex'} flexWrap={'wrap'} justifyContent={'space-around'}
                     border={'1px solid rgba(0,0,0,.32)'} borderRadius={4} height={'80vh'} width={'100%'}>
                    {
                        uploadedImages.map((image, i) => (
                            <div style={{width: 'fit-content'}} key={i}>
                                {
                                    type === 'image' ?
                                        <img style={{width: 300, height: 300, border: '1px solid rgba(0,0,0,.16)'}}
                                             src={image.src} alt={'img'}/>
                                             : type === 'file' ?
                                        <embed src={image.src}/>
                                        :
                                        <video controls autoPlay width="100%" height="auto">
                                            <source src={image.src} type="video/mp4"/>
                                                Your browser does not support the video tag.
                                        </video>

                                    }
                                <p style={{textAlign: 'center', marginTop: 8}}>{image.label} <IconButton
                                    onClick={() => removeImage(image.label)}><Clear/></IconButton></p>
                            </div>
                        ))
                    }

                </Box>
                <Box textAlign={'right'} my={1}>
                    <Button disabled={uploadProgress > 0} onClick={onValidateClicked}
                            size={'large'} color={'primary'}
                            variant={'outlined'}>
                        Valider
                    </Button>

                    <Button onClick={onCloseClicked} style={{marginLeft: 8}} size={'large'} color={'secondary'}
                            variant={'outlined'}>
                        Fermer
                    </Button>
                </Box>
            </Paper>
        </TilBackDrop>
    );
}

export default TilUploadComponent;
