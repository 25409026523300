import { Box, Container, Grid } from "@material-ui/core";
import LandingSectionTitle from "../../../../components/landingSectionTitle";
import { useEffect, useState } from "react";
import CrudService from "../../../../service/crud.service";
import { useMemo } from "react";
import { IMG_URL } from "../../../../config/constants";

export default function References() {
    const service = useMemo(() => new CrudService(), []);
    const [items, setItems] = useState([]);
    useEffect(() => {
        service.getRowsByTableName('reference').then(res => {
            setItems(res)
        })
    }, [service])
  return (
    <Box my={16} id={"our-references"}>
        <LandingSectionTitle
          title={"references-title"}
          subTitle={"references-text"}
        />

        <Box style={{background: "#bae4e0", padding: 16}} textAlign={"center"}>
                <Container>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        {
                            items.map(i => (
                                <Grid item xs={12} md={2}>
                                    <img src={IMG_URL + i.image} height={90}  alt=""/>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Container>
        </Box>
    </Box>
  );
}
