import React, {useEffect, useState} from "react";
import CrudService, {executeQuery} from "../../../../service/crud.service";
import {dateToInput} from "../../../../config/dateUtils";
import {Container, Grid, IconButton, Paper, TextField, Typography} from "@mui/material";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {Check, Close, FormatAlignCenterOutlined, Mail, Phone, Room, Send} from "@material-ui/icons";
import {IMG_URL} from "../../../../config/constants";
import LandingSectionTitle from "../../../../components/landingSectionTitle";
import titleBg from "../../../../assets/images/heading-icon.png";
import {Circle} from "@mui/icons-material";
import formateurImg from "../../../../assets/images/formateur.jpg";
import logo from "../../../../assets/logo-no-bg.png";
import {useMediaQuery} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import {ContactItem} from "../contact";
import {sendEmailInscription} from "../../../../service/mail";
import {Link} from "react-router-dom";
const lang = localStorage.getItem("lang");
const contactItems = [
    {
        link: "https://goo.gl/maps/XbjfiCMxUrGessQx9",
        icon: <Room fontSize={"large"} style={{color: 'rgb(38, 200, 182)'}}/>,
        label: lang === "en" ? 'Visit US' : "Visitez nous",
        text: lang === "en" ? "162 Av. de l'UMA, Ariana Immeble Kammoun Center Bur. C2-10" : "162 Av. de l'UMA, Ariana Immeble Kammoun Center Bur. C2-10",
        variant: "subtitle1"
    },
    {
        icon: <Phone fontSize={"large"} style={{color: 'rgb(38, 200, 182)'}}/>,
        label: "Appelez nous",
        text: "(+216) 51 556 886"
    },
    {
        icon: <Mail fontSize={"large"} style={{color: 'rgb(38, 200, 182)'}}/>,
        label: "E-mail",
        text: "contact@evocare.tn"
    }
]
const SectionTitle = ({title, my = 8, alignItems = 'center', activateOnScrollAnimation = false}) => {
    return (
        <Box data-aos={activateOnScrollAnimation && "zoom-in"} position={'relative'} my={my} display={'flex'}
             alignItems={alignItems} flexDirection={'column'}>
            <img className={"title-bg rotate-scale-up"} src={titleBg} alt={""}/>
            <Typography variant={'h4'}>
                {title}
            </Typography>
            <Box my={2} width={64} height={2} style={{background: 'rgb(38,200,182)'}}/>
        </Box>
    )
}
const Title = ({value}) => (
    <Box display={"flex"} alignItems={"center"} borderBottom={1}>
        <Circle sx={{height: 12}}/>
        <Typography
            variant={"h5"} color={"rgb(38, 200, 182)"}>
            {value}
        </Typography>
    </Box>
)
export default function DetailsFormation({id, onClose}) {
    const [state, setState] = useState({});
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        (async () => {
            if (id) {
                const formation = await executeQuery(`SELECT * FROM formation WHERE id LIKE "${id}"`).then(res => res[0]);
                const objectifsFormation = await executeQuery(`SELECT * FROM ligne_objectif_formation WHERE id_formation LIKE "${id}"`);
                const objectifsPedagogiques = await executeQuery(`SELECT * FROM objectif_pedagogique WHERE id_formation LIKE "${id}"`);
                let lignesObjectifPedagogique = [];
                if (objectifsPedagogiques.length > 0)
                    lignesObjectifPedagogique = await executeQuery(`SELECT * FROM ligne_objectif_pedagogique WHERE id_objectif IN (${objectifsPedagogiques.map(op => op.id)})`);
                const journeesFormation = await executeQuery(`SELECT * FROM journee_formation WHERE id_formation LIKE "${id}"`);
                let sectionsJournee = [];
                if(journeesFormation.length > 0)
                sectionsJournee = await executeQuery(`SELECT * FROM section_formation WHERE id_journee IN (${journeesFormation.map(op => op.id)})`);
                let lignesSectionJournee = [];
                if(sectionsJournee.length>0)
                lignesSectionJournee = await executeQuery(`SELECT * FROM ligne_section WHERE id_section IN (${sectionsJournee.map(op => op.id)})`);
                setState({
                    ...state,
                    formation: {
                        ...formation,
                        date_debut: dateToInput(formation.date_debut),
                        date_fin: dateToInput(formation.date_fin)
                    },
                    objsFormation: objectifsFormation,
                    objectifsPedagogiques,
                    lignesObjectifPedagogique,
                    journeesFormation,
                    sectionsJournee,
                    lignesSectionJournee
                })
            }
        })()
    }, [id])
    const Details = () => (
        <>
            <SectionTitle title={state.formation.titre}/>

            <Grid container sx={{alignItems: "center"}}>
                <Grid item xs={12} md={6}>
                    <Box component={Paper} p={2} sx={{
                        backgroundColor: "#eee!important",
                        "& .MuiTypography-root": {
                            marginBottom: 36
                        }
                    }}>
                        <Typography variant={"h5"}>
                            {state.formation.titre}
                        </Typography>
                        <Typography variant={"body2"}>
                            {state.formation.description}
                        </Typography>

                        <a href="#section-details">
                            <Typography variant={"subtitle1"} sx={{textDecoration: "underline", cursor: "pointer"}}>
                                En savoir plus ...
                            </Typography>
                        </a>
                        <Box display={"flex"} justifyContent={"center"}>
                            <a href={"/#section-inscription"}>
                                <Button sx={{marginLeft: "auto"}} variant={"contained"} color={"primary"}
                                        startIcon={<FormatAlignCenterOutlined/>}>
                                    Pre-inscription
                                </Button>
                            </a>

                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img style={
                        {
                            borderRadius: 8
                        }
                    } width={"100%"} src={IMG_URL + state.formation.image} alt={""}/>
                </Grid>
            </Grid>

            <section id={"section-details"}>
                <SectionTitle title={"Détails de la formation"}/>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Title value={"Descriptif:"}/>
                        <Box p={1}>
                            {state.formation.description}
                        </Box>

                        <Title value={"Modalité de la formation:"}/>
                        <Box p={1}>
                            {state.formation.modalite}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Title value={"Objectifs pédagogiques:"}/>
                        <Box p={1}>
                            {state.formation.description_objectif_pedagogique}
                        </Box>
                        <Box mt={1}>
                            {
                                state.objectifsPedagogiques.map(op => (
                                    <Box key={op.id}>

                                        <Typography variant={"h6"}>
                                            {op.label}
                                        </Typography>
                                        <Box p={1}>
                                            {
                                                state
                                                    .lignesObjectifPedagogique
                                                    .filter(lop => lop.id_objectif === op.id)
                                                    .map(lop => (
                                                        <Box key={lop.id} display={"flex"} alignItems={"center"}>
                                                            <Check color={"secondary"}/>
                                                            <Typography variant={"body2"}>
                                                                {lop.label}
                                                            </Typography>
                                                        </Box>
                                                    ))
                                            }
                                        </Box>
                                    </Box>
                                ))
                            }
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Title value={"Moyens d’encadrement pédagogiques et techniques :"}/>
                        <Box p={1}>
                            {state.formation.moyens_encadrement}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Title value={"Objectif de la formation :"}/>
                        <Box p={1}>
                            {
                                state
                                    .objsFormation
                                    .map(of => (
                                        <Box key={of.id} display={"flex"} alignItems={"center"}>
                                            <Check color={"secondary"}/>
                                            <Typography variant={"body2"}>
                                                {of.label}
                                            </Typography>
                                        </Box>
                                    ))
                            }
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Title value={"Modalités de contrôle des connaissances :"}/>
                        <Box p={1}>
                            {state.formation.modalite_control}
                        </Box>
                    </Grid>
                </Grid>
            </section>


        </>
    )
    const Programme = () => (<>
        <SectionTitle title={"Programme de la formation"}/>
        <Box>
            {
                state
                    .journeesFormation
                    .map(jf => (
                        <Box jey={jf.id} m={2} p={2}>
                            <Title value={jf.label}/>
                            <Grid container spacing={4}>
                                {
                                    state
                                        .sectionsJournee
                                        .filter(sj => sj.id_journee === jf.id)
                                        .map(sj => (
                                            <Grid key={sj.id} item xs={12} md={6}>
                                                <Box component={Paper} m={2}>
                                                    <Typography
                                                        sx={{textAlign: "center", background: "#ccc", borderRadius: 1}}
                                                        variant={"h6"}>
                                                        {sj.label}
                                                    </Typography>
                                                    {
                                                        state
                                                            .lignesSectionJournee
                                                            .filter(lsj => lsj.id_section === sj.id)
                                                            .map(lsj => (
                                                                <Box p={1} key={lsj.id} display={"flex"}
                                                                     alignItems={"center"}>
                                                                    <Check color={"secondary"}/>
                                                                    <Typography variant={"body2"}>
                                                                        {lsj.label}
                                                                    </Typography>
                                                                </Box>
                                                            ))
                                                    }
                                                </Box>
                                            </Grid>
                                        ))
                                }
                            </Grid>
                        </Box>
                    ))
            }
        </Box>

    </>)
    const Formateur = () => (<>
        <SectionTitle title={"Formateur"}/>
        <Box component={Paper} p={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <img width={"100%"} src={formateurImg} alt={""}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <LandingSectionTitle my={0} title={"prof"} subTitle={"prof-s"}/>
                    <Box p={1} display={"flex"} alignItems={"center"} sx={{
                        "& *": {
                            lineHeight: "1.5!important"
                        }
                    }}>
                        <ul>
                            <li>
                                <Typography variant={"body2"}>
                                    <strong>Bourse d’excellence européenne :</strong>chirurgie et imagerie Reproduction
                                    équine et canine 2011-2012, Université de liège-Belgique.
                                </Typography>
                            </li>

                            <li>
                                <Typography variant={"body2"}>
                                    <strong>Doctorat de l’Université de Batna, option :</strong> Imagerie et Chirurgie
                                    Vétérinaire, soutenue (2009), Mention : <strong>très honorable </strong>
                                </Typography>
                            </li>

                            <li>
                                <Typography variant={"body2"}>
                                    <strong>Animal Master of Science,</strong> Animal School, Baghdad
                                    Univerity,Option : Surgery and Radiology, soutenueJuin 1999,Mention : <strong>Very
                                    good. </strong>
                                </Typography>
                            </li>

                            <li>
                                <Typography variant={"body2"}>
                                    <strong>Maitre de Conférences A, </strong>
                                    Département Vétérinaire, Université de Batna.
                                </Typography>
                            </li>

                            <li>
                                <Typography variant={"body2"}>
                                    <strong>Chef de service </strong>
                                    de chirurgie et d’imagerie vétérinaire Reproduction Canine et Equine (Ulg-FMV-Liège)
                                </Typography>
                            </li>

                            <li>
                                <Typography variant={"body2"}>
                                    Plus de cinq livres publiés en chirurgie et imagerie vétérinaire.</Typography>
                            </li>
                        </ul>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </>)
    const inscriptionIS = {
        nom_prenom: "",
        email: "",
        adresse: "",
        telephone: "",
    }
    const Inscription = () => {
        const service = new CrudService();
        const [form, setForm] = useState({...inscriptionIS, id_formation: state.formation.id});
        const resetForm = () => {
            setForm({...inscriptionIS, id_formation: state.formation.id})
        }
        const [message, setMessage] = useState("");
        const submit = async e => {
            e.preventDefault();
            const exists = await executeQuery(`SELECT * FROM inscription_formation WHERE email LIKE "${form.email}" AND id_formation LIKE "${form.id_formation}"`)
            if (exists.length > 0) {
                setMessage("E-mail dèja utilisé!");
                return;
            }
            service.insertRow(form, "inscription_formation")
                .then(res => {
                    setMessage(res ? "Demande d'insciption envoyée avec succès, vous recevrez un e-mail pour la confirmation" : "Un erreur est survenu! veuillez re-essayer plutard")
                    resetForm();
                    sendEmailInscription({
                        email: form.email,
                        adresse: form.adresse,
                        nom_prenom: form.nom_prenom,
                        message: state.formation.titre,
                        telephone: form.telephone
                    })
                })
        }
        return (<section id={"section-inscription"}>
            <SectionTitle title={"Inscription"} activateOnScrollAnimation={false}/>
            <Box p={3} pt={5} m={6} component={Paper} sx={{
                "& *": {
                    lineHeight: "1.5!important",
                }
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}
                          sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center"
                          }}
                    >
                        <img src={logo} alt="" width={"80%"}/>
                    </Grid>
                    <Grid component={Paper} item xs={12} md={8}>
                        <Typography variant={"caption"}>
                            Pour vous inscrire à la formation ou demander plus d'information, veuillez suivre <a style={{textDecoration: "underline"}} href={state.formation.google_form_link} target="_blank">ce lien</a>.
                        </Typography>

                        {/* <form onSubmit={submit}>
                            <TextField
                                value={form.nom_prenom} onChange={e => setForm({...form, nom_prenom: e.target.value})}
                                sx={{mb: 1}} fullWidth size={"small"} label={"Nom et prénom"} required/>
                            <TextField
                                type={"number"}
                                value={form.telephone} onChange={e => setForm({...form, telephone: e.target.value})}
                                sx={{mb: 1}} fullWidth size={"small"} label={"Téléphone"} required/>
                            <TextField
                                value={form.email} onChange={e => setForm({...form, email: e.target.value})}

                                sx={{mb: 1}} fullWidth size={"small"} label={"E-mail"} type={"email"} required/>
                            <TextField
                                value={form.adresse} onChange={e => setForm({...form, adresse: e.target.value})}

                                sx={{mb: 1}} fullWidth size={"small"} label={"Adresse"} required/>
                            <Box display={"flex"} justifyContent={"flex-end"}>
                                <Typography
                                    sx={{flex: 1}}
                                    variant={"caption"}>
                                    {message}
                                </Typography>
                                <Button
                                    type={"submit"}
                                    startIcon={<Send/>} style={{backgroundColor: '#26c8b6!important'}}
                                    variant={"contained"}>
                                    Envoyer
                                </Button>
                            </Box>
                        </form> */}
                    </Grid>

                </Grid>
            </Box>
        </section>)
    }
    const Contact = () => (<>
        <Box
            style={{
                // background: `url(${background})`
            }}
            sx={{
                "& *":{
                    lineHeight: "1.5!important"
                }
            }}
            height={'100%'}>
            <Box>
                <Container>
                    <Grid justifyContent={'space-between'} container spacing={3}>
                        {
                            contactItems.map((c, i) => (
                                <ContactItem animated={false} data={c} key={i} variant={c.variant}/>
                            ))
                        }
                    </Grid>
                </Container>
            </Box>
        </Box>
    </>)
    if (!state.formation)
        return (<>chargement...</>)
    return (
        <Box sx={{background: "#faf9f9"}}>
            <Box
                component={Paper}
                sx={{
                    position: "absolute",
                    top: 0,
                    right: 0
                }}
            >
                <IconButton onClick={onClose}><Close color={"secondary"}/></IconButton>
            </Box>
            <Container sx={{py: 5, px: 3, "& *": {lineHeight: 2.5}}}>
                <Details/>
                <Programme/>
                <Formateur/>
                <Inscription/>
                <Contact/>
            </Container>
        </Box>
    )
}