import "./App.css";
import underConstruction from './assets/images/under-construction.png';
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import Dashboard from './admin/Dashboard';
import SignIn from "./admin/SignIn";
import {Box} from "@material-ui/core";
import IndexV2 from "./IndexV2";
import {createTheme} from "@material-ui/core/styles";
import {useEffect, useState} from "react";
import LoadingComponent from "./components/loading";
import maintenance from './assets/images/maintenance.gif'
const theme = createTheme({
    palette: {
        primary: {
            main: 'rgb(38, 200, 182)'
        }
    }
})


function App() {
    useEffect(() => {
        if(process.env.NODE_ENV === "production"){
            if (!window.location.origin.includes('www') || !window.location.origin.includes("https"))
                window.location.href = "https://www.test.evocare.tn"
        }
        const lang = localStorage.getItem("lang");
        if(!lang)
        {
            localStorage.setItem("lang", "en");
            window.location.reload();
        }
    }, [])
    return (
        // <MuiThemeProvider theme={theme}>
        <Router>
            <Switch>
            {/* <Route exact path="/under-construction">
                    <Box flexDirection={'column'} display={'flex'} justifyContent={'center'} alignItems={'center'}
                         height={'100vh'} width={'100%'}>
                        <img src={maintenance} alt="Site en maintenance" />
                        <h3>Website is under maintenance</h3>
                    </Box>
                </Route> */}
                <Route exact path="/admin-evo-care-tn">
                    <SignIn/>
                </Route>
                <Route path="/admin-module">
                    <Dashboard/>
                </Route>
                <Route path={'/:page?/:category?/:subCategory?'}>
                    <IndexV2/>
                    {/* <Redirect to="/under-construction" /> */}
                    {/* <Box flexDirection={'column'} display={'flex'} justifyContent={'center'} alignItems={'center'}
                         height={'100vh'} width={'100%'}>
                        <img src={maintenance} alt="Site en maintenance" />
                        <h3>Website is under maintenance</h3>
                    </Box> */}
                </Route>
               
               
            </Switch>
        </Router>
        // </MuiThemeProvider>
    );
}

export default App;
