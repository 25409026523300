import axios from "axios";
import {API_URL} from "../config/constants";

export const executeQuery = async query => {
    return await axios.post(
        API_URL + 'get',
        {query},
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
        .then(res => res.data)
        .catch(err => err)
}
export default class CrudService {
    getRowsByTableName = async tableName => {
        const query = `SELECT * FROM ${tableName}`;
        return await axios.post(
            API_URL + 'get',
            {query},
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
            .then(res => res.data)
            .catch(err => err)
    }

    getRowsBy = async (tableName, byAttr, byValue) => {
        const query = `SELECT * FROM ${tableName} WHERE ${byAttr} LIKE '${byValue}'`;
        return await executeQuery(query);
    }

    insertRow = async (row, tableName) => {
        const columns = Object.keys(row);
        const values = Object.values(row).map((value) =>
            value === "" ? "0" : `"${value}"`
        );
        const query = `INSERT INTO ${tableName} (${columns}) VALUES (${values})`;
        return await fetch(API_URL + "create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({query}),
        })
            .then((res) => res.json())
            .catch((err) => err);
    };

    getQuoteProduct = async id => {
        const query = `SELECT p.label, c.label as category_label, c.target  FROM products p JOIN products_categories c ON p.category_id = c.id WHERE p.id LIKE "${id}"`;
        return await axios.post(
            API_URL + 'get',
            {query},
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
            .then(res => res.data)
            .catch(err => err)
    }

    deleteRow = async (id, tableName) => {
        const query = `DELETE FROM ${tableName} WHERE id like ${id}`;
        return await fetch(API_URL + "delete", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({query}),
        })
            .then((res) => res.json())
            .catch((err) => err);
    }

    updateRow = async (row, tableName) => {
        let values = "";
        Object.keys(row).forEach((key, i) => {
            values += ` ${key} = "${row[key]}" ${
                i + 1 !== Object.keys(row).length ? "," : ""
            }`;
        });
        const query = `UPDATE ${tableName} SET ${values} WHERE id like ${row.id}`;
        return await fetch(API_URL + "update", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({query}),
        })
            .then((res) => res.json())
            .catch((err) => err);
    };
}

