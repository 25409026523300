export const sendEmail = (data) => {
    let formData = new FormData();
    formData.append('email',data.email);
    formData.append('message', data.message);

    fetch('https://www.evocare.tn/API/acceptation_email.php', {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(res => {
            console.log("res confirm subscription",res)})
        .catch(error => console.info(error))

}
