import React, {useEffect} from 'react';
import {Box, Button, Container, Grid, Paper, Typography, useMediaQuery} from "@material-ui/core";
import logo from '../../../assets/logo.svg';
import {ArrowRight, Facebook, Instagram, PowerInput} from "@material-ui/icons";
import {HashLink as Link} from 'react-router-hash-link';
import {useTheme} from "@material-ui/core/styles";
import AOS from "aos";
import AppText from "../../../components/app-text";

const CopyRight = () => {
    return (
        <Box py={2} style={{background: '#1C2339'}}>
            <Container>
                <a href="https://kreatek.tn/" target='_blank' rel="noreferrer">
                <Typography variant={'h6'} style={{color: 'white', textAlign: 'center'}}>
                    Copyright © 2021 <span style={{color: 'rgb(38, 200, 182)'}}>KREATEK.TN</span> All Rights Reserved
                </Typography>
                </a>
            </Container>
        </Box>
    )
}

const FooterTitle = ({label, text, titleColor = "white", container="div", icon}) => {
    useEffect(() => {
        AOS.init({
            duration: 500
        });
    }, [])
    return (
        <Box data-aos="zoom-in" component={container === "paper" ? Paper : "div"}>
            <Box display={'flex'}>
                {
                    icon ? <img src={icon} alt={'img'} style={{marginRight: 8, marginBottom: 8}}/> :  <PowerInput fontSize={'large'} style={{color: 'rgb(38,200,182)'}}/>
                }
                <Typography style={{color: titleColor}} variant={'h5'}>
                    <AppText text={label}/>
                </Typography>
            </Box>
            <Box mb={2} height={2} width={'100%'} style={{background: 'rgb(38, 200, 182)'}}/>
            {text}
        </Box>
    )
}

const Footer = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <>
            <Box style={{background: 'rgb(18, 46, 69)'}} mt={!isMobile && 50} p={isMobile ? 1 : 12}>
                <Container>
                    {/*footer header*/}
                    <Grid container display={'flex'} justifyContent={'space-between'}>
                        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} maxHeight={100} overflow={"hidden"}>
                            <img src={logo} alt={'logo'} height={380}/>
                        </Box>
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography variant={'h5'} style={{color: "white"}}><AppText text={"we-are-social"}/> </Typography>
                            <Box ml={1} py={1} px={2} borderRadius={16} border={1} borderColor={'white'}
                                 display={'flex'} alignItems={'center'}>
                                <a target={"_blank"} href={"https://www.facebook.com/Evocare-Tunisie-Equipement-m%C3%A9dical-et-v%C3%A9t%C3%A9rinaire-475500499853806"}>

                                <Facebook fontSize={'large'} style={{color: 'rgb(38, 200, 182)', marginRight: 8}}/>
                                </a>
                                <a href={"https://instagram.com/evolution_healthcare_tunisie?igshid=YmMyMTA2M2Y="} target={"_blank"}>
                                <Instagram fontSize={'large'} style={{color: 'rgb(38, 200, 182)'}}/>
                                </a>
                                </Box>
                        </Box>
                    </Grid>
                    {/*    footer sections*/}
                    {/*section 1*/}
                    <Box mt={8}>
                        <Grid spacing={6} container>
                            <Grid item xs={12} md={4}>
                                <FooterTitle label={'about-section-title'}
                                             text={<Typography variant={'h6'} style={{
                                                 fontSize: '1.1em',
                                                 fontWeight: 'lighter',
                                                 color: 'rgb(226,225,225)',
                                                 textAlign: 'justify'
                                             }}>
                                                 <AppText text={"footer-about"}/>
                                             </Typography>}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <FooterTitle label={'Explore'}
                                             text={
                                                 <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                                     <Link to={"/"}>
                                                         <Button size={'large'} style={{color: 'white'}}
                                                                 startIcon={<ArrowRight
                                                                     style={{color: 'rgb(38,200,183)'}}/>}>
                                                             <AppText text={"Home"}/>
                                                         </Button>
                                                     </Link>
                                                     <Link to={"/#our-references"}>
                                                     <Button size={'large'} style={{color: 'white'}}
                                                             startIcon={<ArrowRight
                                                                 style={{color: 'rgb(38,200,183)'}}/>}>
                                                         <AppText text={"references-title"}/>
                                                     </Button>
                                                     </Link>
                                                     <Link to={"/#our-partners"}>
                                                     <Button size={'large'} style={{color: 'white'}}
                                                             startIcon={<ArrowRight
                                                                 style={{color: 'rgb(38,200,183)'}}/>}>
                                                         <AppText text={"Our Partners"}/>
                                                     </Button>
                                                     </Link>
                                                     <Link to={"/#our-categories"}>
                                                         <Button size={'large'} style={{color: 'white'}}
                                                                 startIcon={<ArrowRight
                                                                     style={{color: 'rgb(38,200,183)'}}/>}>
                                                             <AppText text={"Our Categories"}/>
                                                         </Button>
                                                     </Link>
                                                     <Link to={"/#about-section"}>
                                                         <Button size={'large'} style={{color: 'white'}}
                                                                 startIcon={<ArrowRight
                                                                     style={{color: 'rgb(38,200,183)'}}/>}>
                                                             <AppText text={"About"}/>
                                                         </Button>
                                                     </Link>
                                                   <Link to={"/products"}>
                                                       <Button
                                                           onClick={() => window.scrollTo({
                                                               top: 0,
                                                               left: 0,
                                                               behavior: 'smooth'
                                                           })}
                                                           size={'large'} style={{color: 'white'}}
                                                               startIcon={<ArrowRight
                                                                   style={{color: 'rgb(38,200,183)'}}/>}>
                                                           <AppText text={"our-products"}/>
                                                       </Button>
                                                   </Link>
                                                   <Link to={"/#contact-us"}>
                                                       <Button size={'large'} style={{color: 'white'}}
                                                               startIcon={<ArrowRight
                                                                   style={{color: 'rgb(38,200,183)'}}/>}>
                                                           Contact
                                                       </Button>
                                                   </Link>
                                                 </Box>
                                             }
                                />
                            </Grid>

                            <Grid item xs={12}  md={4} >
                                <FooterTitle label={'working-hours'}
                                             text={
                                                 <>
                                                     <Typography variant={'h6'}
                                                                 style={{color: 'white', fontWeight: 'lighter'}}>
                                                         <AppText text={"working-hours-text"} providedTranslation={"Vous êtes les bienvenus pour nous rendre visite"}/>
                                                     </Typography>
                                                     <Box mt={1} mb={12}>
                                                         <span style={{color: 'rgb(38,200,182)'}}><AppText text={"Mon"}/> - <AppText text={"Fri"}/> </span>: <span
                                                         style={{color: 'white'}}>09:00 - 17:00</span>

                                                     </Box>
                                                 </>
                                             }
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>

            </Box>
            <CopyRight/>
        </>
    )
}

export default Footer;

export {FooterTitle};
