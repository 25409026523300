import React, {useState} from 'react';
import {Box, Button, Container, Grid, Paper, TextField, Typography, useMediaQuery} from "@material-ui/core";
import LandingSectionTitle from "../../../../components/landingSectionTitle";
import {Mail, Phone, Room, Send} from "@material-ui/icons";
import useStyles from "../species/styles";
import background from '../../../../assets/images/contact-bg.png';
import {useTheme} from "@material-ui/core/styles";
import CrudService from "../../../../service/crud.service";
import AppSnackBar from "../../../../components/snackbar";
import {sendEmail} from "../../../../service/mail";
import AppText from "../../../../components/app-text";

const lang = localStorage.getItem("lang");
const contactItems = [
    {
        link: "https://goo.gl/maps/XbjfiCMxUrGessQx9",
        icon: <Room fontSize={"large"} style={{color: 'rgb(38, 200, 182)'}}/>,
        label: lang === "en" ? 'Visit US' : "Visitez nous",
        text: lang === "en" ? "162 Av. de l'UMA, Ariana Immeble Kammoun Center Bur. C2-10" : "162 Av. de l'UMA, Ariana Immeble Kammoun Center Bur. C2-10",
        variant: "subtitle1"
    },
    {
        link: "tel:51 556 886",
        icon: <Phone fontSize={"large"} style={{color: 'rgb(38, 200, 182)'}}/>,
        label: lang === "en" ? 'Call US' : "Appelez nous",
        text: "(+216) 51 556 886"
    },
    {
        link: "mailto:contact@evocare.tn",
        icon: <Mail fontSize={"large"} style={{color: 'rgb(38, 200, 182)'}}/>,
        label: lang === "en" ? 'Email US' : "E-mail",
        text: "contact@evocare.tn"
    }
]

export const ContactItem = ({data, variant = "h6", animated = true}) => {
    return (
        <Grid item xs={12} sm={4} md={4}>

            <Box

                onClick={() => {
                    window.open(data.link, "_blank")
                }}
                sx={{
                    cursor: data.link ? "pointer" : "default"
                }}
                data-aos={animated ? "fade-up" : ""}
                 data-aos-anchor-placement={animated ? "top-bottom" : ""} p={1}>


                <Paper elevation={4} style={{background: 'rgb(18,46,69)'}}>
                    <Box height={209} py={1} display={'flex'} justifyContent={'center'} alignItems={'center'}
                         flexDirection={'column'}>
                        {data.icon}
                        <Box my={3} height={2} style={{backgroundColor: 'white'}} width={100}/>
                        <Typography variant={'h6'} style={{color: 'rgb(38, 200, 182)', fontWeight: 'bolder'}}>
                            {data.label}
                        </Typography>
                        <Typography variant={variant} style={{color: 'white', textAlign: "center"}}>
                            {data.text}
                        </Typography>
                    </Box>
                </Paper>
            </Box>
        </Grid>
    )
}

const Contact = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [openMessage, setOpenMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState(null);
    const [form, setForm] = useState({type: "contact"});
    const crudService = new CrudService();

    const handleFieldChanged = (field, value) => {
        let formWillUpdate = {...form};
        formWillUpdate[field] = value;
        setForm(formWillUpdate)
    }

    const onSubmit = e => {
        e.preventDefault();
        let formWillInsert = {...form};
        delete formWillInsert.type;
        crudService.insertRow(formWillInsert, 'contacts')
            .then(res => {
                setMessage(res ? "Your message has been send! expect from us soon" : "Error sending your message");
                setStatus(res ? "success" : "error");
                setOpenMessage(true);
                sendEmail(form);
            })
    }

    return (
        <Box my={16} id={"contact-us"}>
            <Container data-aos="zoom-in">
                <LandingSectionTitle title={'contact-us'}
                                     subTitle={"contact-us-text"}/>

                <iframe title='map'
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3191.660812173864!2d10.236675481383982!3d36.874536891910246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12e2cbc80aa499f1%3A0xa86ce54d893b854d!2sKAMOUN%20CENTER!5e0!3m2!1sen!2stn!4v1683068877203!5m2!1sen!2stn"
                    width="100%" height="450" style={{borderRadius: 12, border: 0}} allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"/>
                <Box my={4} textAlign={'center'}>
                    <Typography variant={"h5"}>
                        <AppText text={"get-in-touch"}/>
                    </Typography>
                    <Typography variant={"h3"}>
                        <AppText text={"online"}/>
                    </Typography>
                </Box>
                <Box position={'relative'} display={'flex'} justifyContent={'center'}
                     flexDirection={isMobile ? "column" : "row"}>

                    <Paper style={{width: !isMobile && '80%', borderRadius: 12}} elevation={5}>
                        <Box py={6} display={'flex'} justifyContent={'center'}>
                            <form onSubmit={onSubmit} className={classes.form}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        {/*last name*/}
                                        <TextField
                                            required
                                            onChange={e => handleFieldChanged('raison_social', e.target.value)}
                                            style={{margin: isMobile && '8px 0'}}
                                            variant={'outlined'}
                                            label={<AppText text={"name"}/>}
                                            InputLabelProps={{shrink: true}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {/*email*/}
                                        <TextField
                                            required
                                            type={'email'}
                                            onChange={e => handleFieldChanged('email', e.target.value)}
                                            style={{margin: isMobile && '8px 0'}}
                                            variant={'outlined'}
                                            label={'Email'}
                                            InputLabelProps={{shrink: true}}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        {/*phone*/}
                                        <TextField
                                            required
                                            type={'number'}
                                            onChange={e => handleFieldChanged('phone', e.target.value)}
                                            style={{margin: isMobile && '8px 0'}}
                                            variant={'outlined'}
                                            label={<AppText text={'Phone'}/>}
                                            InputLabelProps={{shrink: true}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {/*subject*/}
                                        <TextField
                                            required
                                            onChange={e => handleFieldChanged('subject', e.target.value)}
                                            style={{margin: isMobile && '8px 0'}}
                                            variant={'outlined'}
                                            label={<AppText text={'Subject'}/>}
                                            InputLabelProps={{shrink: true}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        {/*address*/}
                                        <TextField
                                            required
                                            onChange={e => handleFieldChanged('address', e.target.value)}
                                            style={{margin: isMobile && '8px 0'}}
                                            variant={'outlined'} label={<AppText text={'Address'}/>}
                                            InputLabelProps={{shrink: true}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        {/*post code*/}
                                        <TextField
                                            required
                                            type={"number"}
                                            onChange={e => handleFieldChanged('post_code', e.target.value)}
                                            style={{margin: isMobile && '8px 0'}}
                                            variant={'outlined'} label={<AppText text={'post-code'}/>}
                                            InputLabelProps={{shrink: true}}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/*message*/}
                                        <TextField
                                            required
                                            onChange={e => handleFieldChanged('message', e.target.value)}
                                            style={{margin: isMobile && '8px 0'}}
                                            multiline
                                            rows={4}
                                            variant={'outlined'} label={'Message'}
                                            InputLabelProps={{shrink: true}}
                                        />
                                    </Grid>
                                </Grid>

                                <Box textAlign={'center'} mt={4}>
                                    <Button type={'submit'} style={{borderRadius: 12, backgroundColor: '#26c8b6'}}
                                            size={'large'}
                                            endIcon={<Send/>} variant={'contained'}>
                                        <AppText text={"send"}/>
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                        <AppSnackBar open={openMessage} message={message} handleClose={() => setOpenMessage(false)}
                                     status={status}/>
                    </Paper>
                    <Box zIndex={-1} position={!isMobile && 'absolute'} top={'60%'} height={!isMobile && 600}
                         width={isMobile ? "100%" : '98.8vw'}
                        // style={{background: '#F8F8F8'}}
                    >
                        <Box
                            style={{
                                // background: `url(${background})`
                            }}
                            height={'100%'}>
                            <Box pt={isMobile ? 4 : 28}>
                                <Container>
                                    <Grid justifyContent={'space-between'} container spacing={3}>
                                        {
                                            contactItems.map((c, i) => (
                                                <ContactItem data={c} key={i} variant={c.variant}/>
                                            ))
                                        }
                                    </Grid>
                                </Container>
                            </Box>
                        </Box>
                    </Box>
                </Box>


            </Container>
        </Box>
    )
}

export default Contact;
