import { makeStyles } from '@material-ui/styles';
import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { Grow } from '@material-ui/core';
const Message = ({open, severity= 'success', message = "please provider"}) => {
    const classes = useStyles();
    return (
        <Grow in={open}>
            <Alert severity={severity}>{message}</Alert>
        </Grow>
    );
}

export default Message;

const useStyles = makeStyles(theme => ({
    overlay:{
        position: "absolute",
        top:0,
        left:0,
        height: 500,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,.16)'
    }
}))
