import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router';
import TilDataTable from '../../components/TilDataTable/TilDataTable';
import {modules} from '../../config/constants';
import HomeSliders from "../pages/home-sliders";
import ListeFormation from "../pages/formation/list";
import GestionReferences from '../pages/GestionReferences';

const Content = ({classes}) => {
    const {path, url} = useRouteMatch();
    return (
        <main className={classes.content}>
            <div className={classes.toolbar}/>
            <Switch>
                <Route path={`${path}/home-sliders`}>
                    <HomeSliders/>
                </Route>
                {
                    modules.map(module => (
                        <Route key={module.label} exact path={`${path}/${module.label}`}>
                            <TilDataTable dbTableName={module.label} canAdd={module.canAdd} sortables={module.sortables}
                                          relations={module.relations} uploadables={module.uploadables} switchers={module.switchers}/>
                        </Route>
                    ))
                }
                <Route path={`${path}/gestion-formations`}>
                    <ListeFormation/>
                </Route>
            </Switch>
        </main>
    );
}

export default Content;
