import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    overlayButtons:{
        '& .MuiSvgIcon-root':{
            color: 'rgb(38,200,182)',
            "& :hover":{
                background: 'white'
            }
        }
    },
    category:{
        cursor: 'pointer',
        "& :hover":{
            transform: 'scale(1.1)',
            height: '50%'
        }
    },
    activeCategory:{
        borderBottom: '1px solid'
    },
    modalContent:{
        height: '90%',
        width: '90%',
        overflow: 'auto'
    },
    imageCarousel:{
        width: '100%',
        height: '100%'
    },
    form:{
        marginTop: 16,
        "& .MuiFormControl-root":{
            width: '100%',
        }
    }
}))
export default useStyles;
