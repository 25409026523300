import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    table:{
        border: '1px solid rgba(0,0,0,.05)',
        "& td":{
            borderRight: '1px solid rgba(0,0,0,.05)'
        },
        "& th":{
            borderRight: '1px solid rgba(0,0,0,.05)'
        }
    }
}))
