import React, {useEffect, useState} from 'react';
import {Box, Container, Grid, useMediaQuery, useTheme} from "@material-ui/core";
import PartnerComponent from "./PartnerComponent";
import LandingSectionTitle from "../../../../components/landingSectionTitle";
import CrudService from "../../../../service/crud.service";

const Partners = () => {
    const service = new CrudService();
    const [items, setItems] = useState([]);
    useEffect(() => {
        service.getRowsByTableName('partners').then(res => {
            setItems(res)
        })
    }, [])
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (

            <Box my={8} id={'our-partners'}>

                <Container>
                    <LandingSectionTitle title={'OUR PARTNERS'}
                                         subTitle={"partners-sub-title"}/>

                    <Grid justifyContent={'center'} container spacing={isMobile ? 3 : 6}>
                        {
                            items.map(item => (
                                <Grid key={item.id} item xs={12} sm={6} md={2}>
                                    <PartnerComponent image={item.image} label={item.label} link={item.link}/>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Container>
            </Box>
    )
}

export default Partners;
