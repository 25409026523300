import React from 'react';
import {Box, Grid, Link, Typography} from "@material-ui/core";
import {Email, Facebook, Instagram, Phone, Room} from "@material-ui/icons";
import useStyles from "./styles";


const Text = ({value, icon, classes}) => {
    return (
        <Typography className={classes.topBarTitle}
                    style={{color: 'white', display: 'flex', alignItems: 'center', marginLeft: 8}} variant={'h6'}>
            <span style={{textAlign: 'right'}}>{icon}</span>
            <span style={{marginLeft: 4, fontSize: '.7em'}}>{value}</span>
        </Typography>
    )
}


const TopBar = () => {
    const classes = useStyles();


    return (
        <Box display={'flex'} justifyContent={'space-between'} style={{background: 'rgb(18, 46, 69)'}} py={1} px={0}>
            <Grid container className={classes.icons}>
                <Grid item xs={12} md={8}>
                    <Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'}>
                        <a href="mailto:contact@evocare.tn">
                            <Text classes={classes} icon={<Email/>} value={'contact@evocare.tn'}/>
                        </a>
                        <a href="tel:51 556 886">
                            <Text classes={classes} icon={<Phone/>} value={'(+216) 51 556 886'}/>
                        </a>
                        <a rel="noreferrer" href={"https://goo.gl/maps/XbjfiCMxUrGessQx9"} target={"_blank"}>
                            <Text classes={classes} icon={<Room/>}
                                  value={"162 Av. de l'UMA, Ariana Immeble Kammoun Center Bur. C2-10"}/>
                        </a>
                        </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} className={classes.icons}>
                        <span>
                            <span style={{color: 'rgb(38, 200, 182)'}}>Mon - Fri :</span>
                            <span style={{color: 'white'}}>9:00 - 17:00</span>
                        </span>
                        <Box width={2} height={20} style={{background: 'rgb(38,200,182)'}} ml={1}/>
                        <a target={"_blank"}
                           href={"https://www.facebook.com/Evocare-Tunisie-Equipement-m%C3%A9dical-et-v%C3%A9t%C3%A9rinaire-475500499853806"}>
                            <Facebook/>
                        </a>
                        <a href={"https://instagram.com/evolution_healthcare_tunisie?igshid=YmMyMTA2M2Y="}
                           target={"_blank"}>
                            <Instagram/>
                        </a>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default TopBar;

