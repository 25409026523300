import {Box, Checkbox, Paper, TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {Chip, IconButton, Typography, Button, FormControl} from "@mui/material";
import {Cancel, Check, Close, Delete, Send} from "@material-ui/icons";
import {executeQuery} from "../../../service/crud.service";
import {DataGrid} from "@mui/x-data-grid";
import {sendEmail} from "./email";

export default function Inscriptions({formation, onClose}){

    const columns=[
        {
            field: "id",
            headerName: "#",
            renderCell: ({row}) => (
                <Checkbox
                    disabled={row.acceptation === "inscrit"}
                    checked={!!selectedClients.find(client => row.email === client.email)}
                    onChange={_ => onAcceptInscriptionClicked(row)}
                />
            )
        },
        {
            field: "nom_prenom",
            headerName: "Nom et prénom",
            width: 300
        },
        {
            field: "email",
            headerName: "E-mail",
            width: 300
        },
        {
            field: "adresse",
            headerName: "Adresse",
            width: 300
        },
        {
            field: "telephone",
            headerName: "Téléphone",
            width: 100
        },
        {
            field: "etat",
            headerName: "État",
            width: 160,
            renderCell: ({row}) => (<Chip label={row.acceptation} color={row.acceptation === "attente" ? "warning" : "success"}/>)
        },
        {
            field: "actions",
            headerName: "Actions",
            width: 100,
            renderCell: ({row}) => (
                <Box display={"flex"} justifyContent={"space-between"}>
                    <IconButton onClick={() => deleteInscription(row.id)}><Delete color={"error"}/></IconButton>
                </Box>
            )
        }
    ]
    const [rows, setRows] = useState([]);
    const [emailMessage, setEmailMessage] = useState(formation.email_acceptation);
    const [selectedClients, setSelectedClients] = useState([]);
    const deleteInscription = id => {
        executeQuery(`DELETE FROM inscription_formation WHERE id LIKE "${id}"`)
            .then(_ => getInscriptions())
    }
    const onAcceptInscriptionClicked = client => {
        const exists = selectedClients.find(e => e.email === client.email);
        console.log(exists, selectedClients)
        if(exists)
            setSelectedClients(selectedClients.filter(e => e.email !== client.email))
        else
        setSelectedClients([...selectedClients, client])
    }

    const acceptClients = async () => {
        for(let client of selectedClients){
            await executeQuery(`UPDATE inscription_formation SET acceptation = "inscrit" WHERE id LIKE "${client.id}"`)
                .then(_ => {
                })
        }
        getInscriptions();
        sendEmails();
    }
    useEffect(() => {
        if(formation){
            getInscriptions();
        }
    }, [formation])
    const getInscriptions = () => {
        executeQuery(`SELECT * FROM inscription_formation WHERE id_formation LIKE "${formation.id}"`)
            .then(rows => setRows(rows))
    }

    const sendEmails = () => {
        for(let client of selectedClients){
            sendEmail({email: client.email, message: formation.titre + "-" +emailMessage})
        }
        setSelectedClients([]);
    }
    return (
        <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            zIndex={9999}
            position={'fixed'}
            height={'100vh'}
            width={'100%'}
            top={0}
            left={0}
            style={{
                background: 'rgba(0,0,0,.2)'
            }}
        >
            <Box sx={{overflow:"auto"}} height={"90%"} width={"80%"} component={Paper}>
                <Box display={"flex"} justifyContent={"space-between"} px={2} mt={1}>
                    <Typography>
                        {formation.titre}
                    </Typography>
                    <IconButton onClick={onClose}>
                        <Close color={"error"}/>
                    </IconButton>
                </Box>


                <Box height={500} p={2}>
                    <DataGrid rows={rows} columns={columns}/>
                </Box>

                {
                    selectedClients.length > 0 &&
                        <Box p={3}>
                            <TextField variant={"outlined"} value={emailMessage} onChange={e => setEmailMessage(e.target.value)} fullWidth rows={10} multiline label={"E-mail à envoyer"}/>
                           <Box mt={1} display={"flex"} justifyContent={"flex-end"}>
                            <Button
                                startIcon={<Cancel/>}
                                variant={"contained"} style={{marginRight:16}} onClick={() => setEmailMessage(null)} color={"secondary"}>Annuler</Button>
                            <Button
                                startIcon={<Send/>}
                                variant={"contained"} onClick={acceptClients} color={"primary"}>Envoyer</Button>
                           </Box>
                           </Box>
                }

            </Box>
        </Box>
    )
}