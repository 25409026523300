/**This component accepts the database table name and generate crud operations */

import {
    Box,
    Button,
    Divider,
    IconButton,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@material-ui/core";
import {Add, Clear, Search} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import "../../assets/flaticons/flaticon.css";
import frToEnMapper from "../../config/frToEnMapper";
import Confirmation from "./Confirmation";
import Form from "./Form";
import Message from "./Message";
import TilDataTableService from "./service";
import {modules} from "../../config/constants";
import {useStyles} from "./styles";
import TilUploadComponent from "./TilUploadComponent";
import TilTableRow from "./TilTableRow";


const TilDataTable = ({
                          dbTableName,
                          canAdd = true,
                          sortables = [],
                          relations = [],
                          uploadables = [],
                          switchers = []
                      }) => {
        //classes - styles
        const classes = useStyles();
        // service - dao
        const service = new TilDataTableService(dbTableName, relations);
        // columns
        const [columns, setColumns] = useState([]);
        // rows
        const [rows, setRows] = useState([]);
        // delete confirmation state
        const [confirmationOpen, setConfirmationOpen] = useState(false);
        // row will update state
        const [rowWillUpdate, setRowWillUpdate] = useState({id: ""});
        // row will delete state
        const [rowWillDelete, setRowWillDelete] = useState(null);
        // message open state
        const [openMessage, setOpenMessage] = useState(false);
        // message state
        const [message, setMessage] = useState("");
        // sort with
        const [sortWith, setSortWith] = useState("");
        //  sort direction
        const [sortDirections, setSortDirections] = useState([]);
        // database relations rows
        const [relationsRows, setRelationsRows] = useState([]);
        // search value
        const [searchValue, setSearchValue] = useState("");
        // uploader show state
        const [uploadablesShowStates, setUploadablesShowStates] = useState([]);
        // object will manage files
        const [objectIDWillManageFiles, setObjectIDWillManageFiles] = useState(0);
        // rows collapses open state
        const [rowCollapsesOpen, setRowCollapsesOpen] = useState([]);
        // form mode update || create ==> if formMode === "" then form is closed
        const [formMode, setFormMode] = useState("");

        /**
         *
         *
         *
         *
         * listeners
         */

        /**
         *
         *
         * populate collapses open states when rows are set
         */
        useEffect(() => {
            if (rows.length > 0) {
                let collapses = [];
                rows.forEach(row => {
                    collapses.push({
                        rowID: row.id,
                        open: false
                    })
                })
                setRowCollapsesOpen(collapses)
            }
        }, [rows])
        /**
         *
         * set form open when rowWillUpdate.id !== ''
         */
        useEffect(() => {
            setFormMode(rowWillUpdate.id === "" ? "" : "update");
        }, [rowWillUpdate]);
        /**
         *
         * open message popup when message is not empty
         */
        useEffect(() => {
            setOpenMessage(message !== "");
        }, [message]);
        /**
         *
         * set sortable directions
         */
        useEffect(() => {
            if (sortables.length > 0) {
                setSortWith(sortables[0]);
                setSortDirections(
                    sortables.map((sortable) => {
                        return {sortable, direction: "desc"};
                    })
                );
            }
        }, [sortables]);
        /**
         *
         * not impl
         */
        useEffect(() => {
        }, [sortDirections]);
        /**
         *
         * populate uploadable show field and multiple states
         */
        useEffect(() => {
            if (uploadables.length > 0) {
                let uploadablesShowStatesWU = [];
                uploadables.forEach(uploadable => {
                    uploadablesShowStatesWU.push({
                        field: uploadable.field,
                        open: false,
                        multiple: uploadable.multiple,
                        displayInTable: uploadable.displayInTable,
                        type: uploadable.type
                    })
                })
                setUploadablesShowStates(uploadablesShowStatesWU);
            }
        }, [uploadables])
        /**
         *
         *
         *
         *
         * Sort data when sortWith and direction changes
         */
        useEffect(() => {
            if (sortWith === "" || sortDirections.length === 0) return;
            const direction = sortDirections.find(
                (sd) => sd.sortable === sortWith
            ).direction;
            setRows(
                rows.sort((a, b) =>
                    {
                        const aCompare = new Date(a[sortWith]) instanceof Date ? new Date(a[sortWith]) : a[sortWith];
                        const bCompare = new Date(b[sortWith]) instanceof Date ? new Date(b[sortWith]) : b[sortWith];

                        return direction === "desc"
                            ? bCompare - aCompare
                            : aCompare - bCompare
                    }
                )
            );
        }, [sortWith, sortDirections]);
        /**
         *
         * prepare object will update when columns are set and form is open
         */
        useEffect(() => {
            if (formMode === "" && columns.length > 0) {
                let object = {};
                columns.forEach((c) => {
                    object[c.Field] = "";
                });
                setRowWillUpdate(object);
            }
        }, [formMode, columns]);
        /**
         *
         * show delete confirmation when row is selected for delete
         */
        useEffect(() => {
            setConfirmationOpen(rowWillDelete !== null);
        }, [rowWillDelete]);
        /**
         *
         * fetch and set columns and rows when db table name is set
         */
        useEffect(() => {
            if (dbTableName) {
                service.getTableColumns().then((columns) => setColumns(columns));
                service.getRows().then((rows) => setRows(rows));
            }
        }, [dbTableName]);

        /**
         *
         * fetch and set db relations when relations config is set
         */
        useEffect(() => {
            (async () => {
                if (relations.length > 0) {
                    let relationRowsWillFetch = [];
                    let index = 0;
                    while (index < relations.length) {
                        const foreignRows = await service.getForeignRows(
                            relations[index].table
                        );
                        relationRowsWillFetch.push({
                            label: relations[index].table,
                            joinOn: relations[index].on,
                            rows: foreignRows,
                        });
                        index++;
                    }
                    setRelationsRows(relationRowsWillFetch);
                }
            })()

        }, [relations]);

        /**
         *
         *
         *
         *
         * functions
         */

        /**
         *
         * open uploader for specified field and selected object
         * @param field
         * @param id
         */
        const handleOpenUploadable = (field, id) => {
            let uploadablesShowStatesWU = [...uploadablesShowStates];
            let index = uploadablesShowStatesWU.findIndex(o => o.field === field);
            uploadablesShowStatesWU[index].open = !uploadablesShowStatesWU[index].open;
            setUploadablesShowStates(uploadablesShowStatesWU);
            setObjectIDWillManageFiles(id)
        }

        /**
         *
         * delete selected row for delete when confirmation
         */
        const deleteRow = () => {
            service
                .deleteRow(rowWillDelete.id)
                .then((res) => {
                    console.log(uploadables.findIndex(u => u.multiple))
                    onRowDeleted()
                })
                .catch((err) => alert(err));
        };

        /**
         *
         * push new created row to state
         * @param row
         */
        const onRowCreated = (row) => {
            console.log(row)
            const id = Math.max(...rows.map((r) => Number(r.id)));
            row.id = isFinite(id) ? id + 1 : 1;
            setRows([...rows, row]);
            setFormMode("");
            setMessage("Succès de creation");
        };
        /**
         *
         * update row in state when updated in db
         * @param rowWillUpdate
         */
        const onRowUpdated = async (rowWillUpdate) => {
            const index = rows.findIndex((row) => row.id === rowWillUpdate.id);
            if (index === -1) return;
            let rowsWillUpdate = [...rows];
            rowsWillUpdate[index] = await service.getRowByID(rowWillUpdate.id);
            setRows(rowsWillUpdate);
            setFormMode("");
            setMessage("Succès de mise à jour");
        };

        const updateRowFile = (field, url, id) => {
            let index = rows.findIndex(row => row.id === id);
            let rowsWillUpdate = [...rows];
            rowsWillUpdate[index][field] = url;
            setRows(rowsWillUpdate)
        }

        /**
         *
         *
         * on row deleted
         * @param rowWillDelete -- state
         */
        const onRowDeleted = () => {
            setRows(rows.filter(row => row.id !== rowWillDelete.id));
            setRowWillDelete(null)
        }

        /**
         *
         * filter rows depending on search value
         * @returns {*[]}
         */
        const filteredRows = () => {
            return rows.filter(row => {
                let rowString = JSON.stringify(Object.values(row).join(',').toLowerCase());
                return rowString.includes(searchValue.toLowerCase())
            })
        }

        const handleCollapseButtonClicked = (row) => {
            let collapses = [];
            rowCollapsesOpen.forEach(collapse => {
                collapses.push({
                    rowID: collapse.rowID,
                    open: false
                })
            });
            collapses[collapses.findIndex(collapse => collapse.rowID === row.id)].open =
                !collapses[collapses.findIndex(collapse => collapse.rowID === row.id)].open;
            setRowCollapsesOpen(collapses)
        }

        /**
         *
         *
         *
         *
         *
         *
         *
         * Render functions
         */


        return (
            <div style={{margin: "8px 32px"}}>
                <Box display="flex">
                    {/*Title*/}
                    <Box flex="3">
                        <h1>Gestion des {frToEnMapper(dbTableName, true)}</h1>
                    </Box>
                    {/*end title*/}
                    {/*search field*/}
                    <Box display="flex" alignItems="center" flex="1">
                        <TextField
                            value={searchValue}
                            onChange={e => setSearchValue(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search/>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton onClick={() => setSearchValue('')}>
                                            <Clear/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            style={{width: "100%"}}
                            label="Recherche"
                            variant="outlined"
                        />
                    </Box>
                    {/*    end search field*/}
                </Box>
                <Divider/>
                {/*Add button && success message*/}
                {canAdd && (
                    <Box my={2} display="flex">
                        <Box flex="1">
                            <Button
                                size="large"
                                onClick={() => setFormMode("create")}
                                startIcon={<Add/>}
                                variant="contained"
                                color="primary"
                            >
                                Ajouter
                            </Button>
                        </Box>
                        <Message
                            open={openMessage}
                            message={message}
                            severity={message.includes("Erreur") ? "error" : "success"}
                        />
                    </Box>

                )}
                {/*end add button && success message*/}

                {/*data display*/}
                <TableContainer style={{maxHeight: 500}} component={Paper}>
                    <Table className={classes.table} style={{position: "relative"}} stickyHeader size={"small"}>
                        <TableHead>
                            <TableRow>
                                {
                                    uploadables.filter(u => u.displayInTable).map(u => (
                                        <TableCell>
                                            {
                                                u.field
                                            }
                                        </TableCell>
                                    ))
                                }
                                {columns.filter(c => uploadables.findIndex(u => u.field === c.Field) === -1).map((c) => (
                                    <TableCell key={c.Field} align={'center'}>
                                        {sortables.findIndex((o) => o === c.Field) !== -1 && (
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    setSortWith(c.Field);
                                                    let sortDirectionsWillUpdate = [...sortDirections];
                                                    const index = sortDirections.findIndex(
                                                        (sd) => sd.sortable === c.Field
                                                    );
                                                    const direction =
                                                        sortDirectionsWillUpdate[index].direction;
                                                    sortDirectionsWillUpdate[index].direction =
                                                        direction === "desc" ? "asc" : "desc";
                                                    setSortDirections(sortDirectionsWillUpdate);
                                                }}
                                            >
                                                {sortDirections.find((sd) => sd.sortable === c.Field)
                                                    .direction === "desc" ? (
                                                    <i className="flaticon-sort-by-attributes"/>
                                                ) : (
                                                    <i className="flaticon-sort-by-attributes-interface-button-option"/>
                                                )}
                                            </IconButton>
                                        )}
                                        {frToEnMapper(c.Field)}
                                    </TableCell>
                                ))}
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.length > 0 ? (
                                (searchValue === '' ? rows : filteredRows()).map((row) => (
                                    <TilTableRow  handleOpenUploadable={handleOpenUploadable}
                                                 setRowWillDelete={setRowWillDelete} setRowWillUpdate={setRowWillUpdate}
                                                 uploadables={uploadables} row={row} key={row.id}/>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell
                                        colSpan={columns.length + 1}
                                        align="center"
                                        height="300"
                                    >
                                        Table Vide
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/*end data display*/}

                {/*Modal Form*/}
                <Form
                    mode={formMode}
                    rows={rows}
                    columns={columns}
                    uploadables={uploadables}
                    relations={relationsRows}
                    staticValues={modules.find(module => module.label === dbTableName).staticValues}
                    onRowUpdated={onRowUpdated}
                    onRowCreated={onRowCreated}
                    row={rowWillUpdate}
                    dbTableName={dbTableName}
                    service={service}
                    open={formMode !== ""}
                    handleClose={() => setFormMode("")}
                    fields={columns.map((c) => (c.label = c.Field))}
                    switchers={switchers}
                />
                {/*end modal form*/}


                {/*delete confirmation*/}
                <Confirmation
                    agreeClicked={deleteRow}
                    dbTableName={dbTableName}
                    label={rowWillDelete && rowWillDelete.label}
                    open={confirmationOpen}
                    handleClose={() => {
                        setConfirmationOpen(false);
                        setRowWillDelete(null);
                    }}
                />

                {/*end delete confirmation*/}


                {/*uploader*/}
                {
                    uploadablesShowStates.length > 0 &&
                    uploadablesShowStates.map(uploadable => (
                        uploadable.open &&
                        <TilUploadComponent
                            key={uploadable.field}
                            rows={rows}
                            fileField={uploadable.field}
                            service={service}
                            tableName={dbTableName}
                            objectID={objectIDWillManageFiles}
                            onCloseClicked={() => {
                                handleOpenUploadable(uploadable.field);
                                setObjectIDWillManageFiles(0)
                            }}
                            field={uploadable.field}
                            multiple={uploadable.multiple}
                            displayInTable={uploadable.displayInTable}
                            onDisplayInTableFileUploaded={updateRowFile}
                            type={uploadable.type}
                        />
                    ))
                }

                {/*end uploader*/}

                {/*html logger*/}
                <div id='logs-container' style={{position: 'absolute', bottom: 0}}/>
                {/*    end html logger*/}
            </div>
        );
    }
;

export default TilDataTable;
