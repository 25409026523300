import {Box} from "@material-ui/core";
import titleBg from "../../assets/images/heading-icon.png";
import React, {useEffect} from "react";
import logo from '../../assets/logo-no-bg.png';
export default function LoadingComponent() {
    useEffect(() => {
        document.querySelector("body").style.overflow = "hidden";
        return () => document.querySelector("body").style.overflow = "auto";
    }, [])
    return (
        <Box sx={{background: "rgba(0,0,0,.6)", zIndex:9999}}
             position={"absolute"}
             height={"100vh"}
             width={"100%"}
             display={"flex"}
             justifyContent={"center"}
             alignItems={"center"}
             top={0}
        >
            <img style={{opacity: .4}} className={"title-bg rotate-scale-up"} src={titleBg} alt={""}/>
            <img height={80} src={logo} alt={""}/>

        </Box>
    )
}