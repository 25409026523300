export let initialState = {
    formation:{
        titre: "", description: "", image: "", max_inscription: 0, id_formateur: 1, modalite: "",
        description_objectif_pedagogique: "", moyens_encadrement: "", modalite_control: "",
        description_objectif_formation: "", etat: "ouverte", date_debut: "", date_fin: "", google_form_link: "", category: ""
    },
    objectifsPedagogiques: [],
    lignesObjectifPedagogique: [],
    objectifsFormation:[],
    objsFormation: [],
    journeesFormations:[],
    sectionsJournee:[],
    lignesSectionJournee: [],
    email_acceptation: ""
}

export const formationIS = {
    titre: "", description: "", image: null, max_inscription: 0, id_formateur: 0, modalite: "",
    description_objectif_pedagogique: "", moyens_encadrement: "", modalite_control: "",
    description_objectif_formation: "", etat: "open", date_debut: "", date_fin: ""
};

export const objectifPedagiqueIS = {
    label: "",
    id_formation: 0
};
export const ligneObjectifPedagiqueIS = {
    label: "",
    id_objectif: 0
};
export const lignesObjectifFormationIS = {
    label: "",
    id_formation: 0
};
export const sectionFormationIS = {
    label: "",
    time_debut: null,
    time_fin: null,
    id_journee: 0
};
export const ligneSectionFormationIS = {
    label: "",
    id_section: 0
};

export const journeeFormationIS = {
    label: "",
    id_formation: 0
}
