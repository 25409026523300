import Button from "@material-ui/core/Button";
import { Add, AddOutlined, Clear, Image, Save } from "@material-ui/icons";
import { Circle } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import imagePlaceholder from "../../../assets/images/placeholder.jpg";
import { API_URL, IMG_URL } from "../../../config/constants";
import { dateToInput } from "../../../config/dateUtils";
import CrudService, { executeQuery } from "../../../service/crud.service";
import { initialState } from "./initial-states";
import { FormControl, InputLabel, MenuItem, Select, makeStyles } from "@material-ui/core";

const service = new CrudService();
const SectionContainer = ({ children }) => {
  return (
    <Box p={2} m={2} component={Paper} sx={{ background: "#fafafa" }}>
      {children}
    </Box>
  );
};
const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
export default function FormulaireFormation({ data = {}, onClose }) {
  const [state, setState] = useState(initialState);
  const [formationWillUpdate, setFormationWillUpdate] = useState({});
  const [updateIsSet, setUpdateIsSet] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    (async () => {
      if (data.id) {
        const formation = await executeQuery(
          `SELECT * FROM formation WHERE id LIKE "${data.id}"`
        ).then((res) => res[0]);
        const objectifsFormation = await executeQuery(
          `SELECT * FROM ligne_objectif_formation WHERE id_formation LIKE "${data.id}"`
        );
        const objectifsPedagogiques = await executeQuery(
          `SELECT * FROM objectif_pedagogique WHERE id_formation LIKE "${data.id}"`
        );
        let lignesObjectifPedagogique = [];
        if (objectifsPedagogiques.length > 0)
          lignesObjectifPedagogique = await executeQuery(
            `SELECT * FROM ligne_objectif_pedagogique WHERE id_objectif IN (${objectifsPedagogiques.map(
              (op) => op.id
            )})`
          );
        const journeesFormations = await executeQuery(
          `SELECT * FROM journee_formation WHERE id_formation LIKE "${data.id}"`
        );
        let sectionsJournee = [];
        if (journeesFormations.length > 0) {
          sectionsJournee = await executeQuery(
            `SELECT * FROM section_formation WHERE id_journee IN (${journeesFormations.map(
              (op) => op.id
            )})`
          );
        }
        let lignesSectionJournee = [];
        if (sectionsJournee.length > 0)
          lignesSectionJournee = await executeQuery(
            `SELECT * FROM ligne_section WHERE id_section IN (${sectionsJournee.map(
              (op) => op.id
            )})`
          );
        setState({
          ...state,
          formation: {
            ...formation,
            date_debut: dateToInput(formation.date_debut),
            date_fin: dateToInput(formation.date_fin),
          },
          objsFormation: objectifsFormation,
          objectifsPedagogiques,
          lignesObjectifPedagogique,
          journeesFormations,
          sectionsJournee,
          lignesSectionJournee,
        });
      }
    })();
  }, [data]);
  const submit = async () => {
    // check formation exists
    if (state.formation.id)
      await executeQuery(
        `DELETE FROM formation WHERE id LIKE "${state.formation.id}"`
      ).then((res) => console.log("res delete: ", res));
    //    save formation and get id
    const formationDB = await service
      .insertRow(state.formation, "formation")
      .then((_) =>
        executeQuery(
          "SELECT * FROM formation WHERE id LIKE (SELECT MAX(id) FROM formation)"
        )
      )
      .then((res) => res[0]);
    setState({
      ...state,
      formation: {
        ...formationDB,
        date_debut: dateToInput(formationDB.date_debut),
        date_fin: dateToInput(formationDB.date_fin),
      },
    });
    //    save objectifPedagogique[i] and get id
    for (let op of state.objectifsPedagogiques) {
      const opDB = await service
        .insertRow(
          { ...op, id_formation: formationDB.id },
          "objectif_pedagogique"
        )
        .then((_) =>
          executeQuery(
            "SELECT * FROM objectif_pedagogique WHERE id LIKE (SELECT MAX(id) FROM objectif_pedagogique)"
          )
        )
        .then((res) => res[0]);
      console.log("opDB: ", opDB);
      // save lignes_objectifs_pedagogiques
      for (let lop of state.lignesObjectifPedagogique.filter(
        (l) => l.id_objectif === op.id
      )) {
        const lopDB = await service
          .insertRow(lop, "ligne_objectif_pedagogique")
          .then((_) =>
            executeQuery(
              "SELECT * FROM ligne_objectif_pedagogique WHERE id LIKE (SELECT MAX(id) FROM ligne_objectif_pedagogique)"
            )
          )
          .then((res) => res[0]);
        console.log("lopDB: ", lopDB);
      }
    }

    // save lignes objectifs formations
    for (let lof of state.objsFormation) {
      const lofDB = await service
        .insertRow(
          { ...lof, id_formation: formationDB.id },
          "ligne_objectif_formation"
        )
        .then((_) =>
          executeQuery(
            "SELECT * FROM ligne_objectif_formation WHERE id LIKE (SELECT MAX(id) FROM ligne_objectif_formation)"
          )
        )
        .then((res) => res[0]);
      console.log("lofDB: ", lofDB);
    }
    // save journee formation
    for (let jf of state.journeesFormations) {
      const jfDB = await service
        .insertRow({ ...jf, id_formation: formationDB.id }, "journee_formation")
        .then((_) =>
          executeQuery(
            "SELECT * FROM journee_formation WHERE id LIKE (SELECT MAX(id) FROM journee_formation)"
          )
        )
        .then((res) => res[0]);
      console.log("jfDB: ", jfDB);
      // save section journee
      for (let sj of state.sectionsJournee.filter(
        (s) => s.id_journee === jf.id
      )) {
        const sjDB = await service
          .insertRow(sj, "section_formation")
          .then((_) =>
            executeQuery(
              "SELECT * FROM section_formation WHERE id LIKE (SELECT MAX(id) FROM section_formation)"
            )
          )
          .then((res) => res[0]);
        console.log("sjDB: ", sjDB);
        // save ligne section journee
        for (let lsj of state.lignesSectionJournee.filter(
          (l) => l.id_section === sj.id
        )) {
          const lsjDB = await service
            .insertRow(lsj, "ligne_section")
            .then((_) =>
              executeQuery(
                "SELECT * FROM ligne_section WHERE id LIKE (SELECT MAX(id) FROM ligne_section)"
              )
            )
            .then((res) => res[0]);
          console.log("lsjDB: ", lsjDB);
        }
      }
    }
  };
  const handleChange = (object, field, value) => {
    setState((prev) => ({
      ...prev,
      [object]: {
        ...state[object],
        [field]: value,
      },
    }));
  };

  const resetState = () => {
    setState(initialState);
  };

  const uploadImage = (e) => {
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    axios
      .post(API_URL + `upload-formation-image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
      })

      .then((res) => {
        handleChange("formation", "image", res.data);
      })
      .catch((err) => console.log(err));
  };

  /**
   * objectifs pedagogiques
   * @returns {Promise<void>}
   */
  const addObjectifPedagogique = async () => {
    const maxID = await executeQuery(
      "SELECT MAX(id) as value FROM objectif_pedagogique"
    ).then((res) => res[0].value);
    setState({
      ...state,
      objectifsPedagogiques: [
        ...state.objectifsPedagogiques,
        {
          id: maxID
            ? maxID + state.objectifsPedagogiques.length
            : state.objectifsPedagogiques.length + 1,
          label: "",
          id_formation: 0,
        },
      ],
    });
  };

  const handleChangeObjectifPedagogique = (index, label) => {
    const objectifsPedagogiques = [...state.objectifsPedagogiques];
    objectifsPedagogiques[index].label = label;
    setState({
      ...state,
      objectifsPedagogiques,
    });
  };

  const deleteObjectifPedagogique = (id) => {
    setState({
      ...state,
      objectifsPedagogiques: state.objectifsPedagogiques.filter(
        (o) => o.id !== id
      ),
      lignesObjectifPedagogique: lignesObjectifPedagogique.filter(
        (l) => l.id_objectif !== id
      ),
    });
  };

  /**
   * lignes objectifs pedagogiques
   */

  const addLigneObjectifPedagogique = async (id_objectif) => {
    const maxID = await executeQuery(
      "SELECT MAX(id) as value FROM ligne_objectif_pedagogique"
    ).then((res) => res[0].value);
    setState({
      ...state,
      lignesObjectifPedagogique: [
        ...state.lignesObjectifPedagogique,
        {
          id: maxID
            ? maxID + state.lignesObjectifPedagogique.length
            : state.lignesObjectifPedagogique.length + 1,
          label: "",
          id_objectif,
        },
      ],
    });
  };

  const handleChangeLigneObjectifPedagogique = (id, label) => {
    const rows = [...state.lignesObjectifPedagogique];
    const index = rows.findIndex((r) => r.id === id);
    rows[index].label = label;
    setState({
      ...state,
      lignesObjectifPedagogique: rows,
    });
  };

  const deleteLigneObjectifPedagogique = (id) => {
    setState({
      ...state,
      lignesObjectifPedagogique: state.lignesObjectifPedagogique.filter(
        (o) => o.id !== id
      ),
    });
  };

  /**
   * objectifs formation
   * @returns {Promise<void>}
   */
  const addObjectifFormation = async () => {
    const maxID = await executeQuery(
      "SELECT MAX(id) as value FROM ligne_objectif_formation"
    ).then((res) => res[0].value);
    setState({
      ...state,
      objsFormation: [
        ...state.objsFormation,
        {
          id: maxID
            ? maxID + state.objsFormation.length
            : state.objsFormation.length + 1,
          label: "",
          id_formation: 0,
        },
      ],
    });
  };

  const handleChangeObjectifFormation = (index, label) => {
    const rows = [...state.objsFormation];
    rows[index].label = label;
    setState({
      ...state,
      objectifsFormation: rows,
    });
  };

  const deleteObjectifFormation = (id) => {
    setState({
      ...state,
      objsFormation: state.objsFormation.filter((o) => o.id !== id),
    });
  };

  /**
   * journée formation
   */

  const addJourneeFormation = async () => {
    const maxID = await executeQuery(
      "SELECT MAX(id) as value FROM journee_formation"
    ).then((res) => res[0].value);
    setState({
      ...state,
      journeesFormations: [
        ...state.journeesFormations,
        {
          id: maxID
            ? maxID + state.journeesFormations.length
            : state.journeesFormations.length + 1,
          label: "",
          id_formation: 0,
        },
      ],
    });
  };
  const handleChangeJourneeFormation = (id, label) => {
    const rows = [...state.journeesFormations];
    const index = rows.findIndex((r) => r.id === id);
    rows[index].label = label;
    setState({
      ...state,
      journeesFormations: rows,
    });
  };
  const deleteJourneeFormation = (id) => {
    setState({
      ...state,
      journeesFormations: state.journeesFormations.filter((o) => o.id !== id),
      sectionsJournee: state.sectionsJournee.filter((o) => o.id_journee !== id),
      lignesSectionJournee: state.lignesSectionJournee.filter((o) =>
        state.sectionsJournee
          .filter((sj) => sj.id_journee === id)
          .map((sj) => sj.id)
          .includes(o.id_section)
      ),
    });
  };

  /**
   * section journee
   */
  const addSectionJournee = async (id_journee) => {
    const maxID = await executeQuery(
      "SELECT MAX(id) as value FROM section_formation"
    ).then((res) => res[0].value);
    setState({
      ...state,
      sectionsJournee: [
        ...state.sectionsJournee,
        {
          id: maxID
            ? maxID + state.sectionsJournee.length
            : state.sectionsJournee.length + 1,
          label: "",
          id_journee,
        },
      ],
    });
  };
  const handleChangeSectionJournee = (id, label) => {
    const rows = [...state.sectionsJournee];
    const index = rows.findIndex((r) => r.id === id);
    rows[index].label = label;
    setState({
      ...state,
      sectionsJournee: rows,
    });
  };
  const deleteSectionJournee = (id) => {
    setState({
      ...state,
      sectionsJournee: state.sectionsJournee.filter((o) => o.id !== id),
      lignesSectionJournee: state.lignesSectionJournee.filter(
        (sj) => sj.id_section !== id
      ),
    });
  };

  /**
   * ligne section journee
   */
  const addLigneSectionJournee = async (id_section) => {
    const maxID = await executeQuery(
      "SELECT MAX(id) as value FROM ligne_section"
    ).then((res) => res[0].value);
    setState({
      ...state,
      lignesSectionJournee: [
        ...state.lignesSectionJournee,
        {
          id: maxID
            ? maxID + state.lignesSectionJournee.length
            : state.lignesSectionJournee.length + 1,
          label: "",
          id_section,
        },
      ],
    });
  };
  const handleChangeLigneSectionJournee = (id, label) => {
    const rows = [...state.lignesSectionJournee];
    const index = rows.findIndex((r) => r.id === id);
    rows[index].label = label;
    setState({
      ...state,
      lignesSectionJournee: rows,
    });
  };
  const deleteLigneSectionJournee = (id) => {
    setState({
      ...state,
      lignesSectionJournee: state.lignesSectionJournee.filter(
        (sj) => sj.id !== id
      ),
    });
  };

  useEffect(() => {
    console.log(state);
  }, [state]);
  const {
    formation,
    objectifsPedagogiques,
    lignesObjectifPedagogique,
    journeesFormations,
    sectionsJournee,
    lignesSectionJournee,
  } = state;
  if (formationWillUpdate.id && !updateIsSet) return <p>Chargement...</p>;
  return (
    <Box
      component={Paper}
      height={"100%"}
      width={"100%"}
      p={2}
      overflow={"auto"}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ borderBottom: 1 }}
      >
        <Typography variant={"h4"}>
          {data.id ? "Mise à jour formation" : "Créer une nouvelle formation"}
        </Typography>
        <IconButton onClick={onClose}>
          <Clear color={"error"} />
        </IconButton>
      </Box>

      <SectionContainer>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label={"Titre"}
              fullWidth
              onChange={(e) =>
                handleChange("formation", "titre", e.target.value)
              }
              value={formation.titre}
            />
            <TextField
              onChange={(e) =>
                handleChange("formation", "description", e.target.value)
              }
              value={formation.description}
              sx={{ mt: 1 }}
              fullWidth
              multiline
              rows={7}
              label={"Description"}
            />
            <Grid container spacing={2} sx={{ mt: 0.5 }}>
              <Grid item xs={6}>
                <TextField
                  onChange={(e) =>
                    handleChange("formation", "date_debut", e.target.value)
                  }
                  value={formation.date_debut}
                  type={"date"}
                  label={"date début: "}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  onChange={(e) =>
                    handleChange("formation", "date_fin", e.target.value)
                  }
                  value={formation.date_fin}
                  type={"date"}
                  label={"date fin: "}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 0.5 }}>
              <Grid item xs={6}>
                <TextField
                  onChange={(e) =>
                    handleChange("formation", "max_inscription", e.target.value)
                  }
                  value={formation.max_inscription}
                  type={"number"}
                  label={"max inscription: "}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  onChange={(e) =>
                    handleChange(
                      "formation",
                      "google_form_link",
                      e.target.value
                    )
                  }
                  value={formation.google_form_link}
                  type={"text"}
                  label={"lien google form: "}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Box display={"flex"} alignItems={"center"}>
                <select style={{flex:1, padding: "18px 8px", border: "1px solid lightgray", borderRadius: 6, background: "transparent"}} value={formation.category} onChange={(e) =>
                    handleChange(
                      "formation",
                      "category",
                      e.target.value
                    )
                  }>
                    <option value={""}>Séléctionner une Catégorie</option>  
                    <option value={"hum"}>Humain</option>  
                    <option value={"vet"}>Vétérinaire</option>  
                    <option value={"env"}>Environnement</option>  
                </select>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <img
              style={{ cursor: "pointer" }}
              src={
                formation.image ? IMG_URL + formation.image : imagePlaceholder
              }
              height={"90%"}
              width={"100%"}
              alt={"img"}
            />
            <Button component={"label"} color={"primary"} variant={"contained"}>
              <Image />
              <input onChange={uploadImage} type="file" hidden />
            </Button>
          </Grid>
        </Grid>
      </SectionContainer>

      <SectionContainer>
        <Typography variant={"h6"}>Modalité de la formation:</Typography>
        <TextField
          onChange={(e) =>
            handleChange("formation", "modalite", e.target.value)
          }
          value={formation.modalite}
          fullWidth
          multiline
          rows={4}
        />
      </SectionContainer>

      <SectionContainer>
        <Typography variant={"h6"}>
          Moyens d’encadrement pédagogiques et techniques :
        </Typography>
        <TextField
          onChange={(e) =>
            handleChange("formation", "moyens_encadrement", e.target.value)
          }
          value={formation.moyens_encadrement}
          fullWidth
          multiline
          rows={4}
        />
      </SectionContainer>

      <SectionContainer>
        <Typography variant={"h6"}>
          Modalités de contrôle des connaissances :
        </Typography>
        <TextField
          onChange={(e) =>
            handleChange("formation", "modalite_control", e.target.value)
          }
          value={formation.modalite_control}
          fullWidth
          multiline
          rows={4}
        />
      </SectionContainer>

      <SectionContainer>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant={"h6"}>Objectifs pédagogiques:</Typography>
            <TextField
              onChange={(e) =>
                handleChange(
                  "formation",
                  "description_objectif_pedagogique",
                  e.target.value
                )
              }
              value={formation.description_objectif_pedagogique}
              label={"description"}
              fullWidth
              multiline
              rows={2}
            />
          </Grid>

          <Grid item xs={6}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              borderBottom={1}
            >
              <Typography variant={"h6"}>Traits:</Typography>
              <Button
                onClick={addObjectifPedagogique}
                variant={"outlined"}
                color={"primary"}
              >
                <Add />
              </Button>
            </Box>
            <Box mt={1}>
              {objectifsPedagogiques.map((o, i) => (
                <Box
                  sx={{ background: "#cec" }}
                  key={"o-" + i}
                  component={Paper}
                  m={1}
                  p={1}
                >
                  <Box mt={1} display={"flex"}>
                    <TextField
                      variant={"standard"}
                      sx={{ flex: 1 }}
                      value={o.label}
                      onChange={(e) =>
                        handleChangeObjectifPedagogique(i, e.target.value)
                      }
                    />
                    <IconButton
                      onClick={() => addLigneObjectifPedagogique(o.id)}
                    >
                      <Add />
                    </IconButton>
                    <IconButton onClick={() => deleteObjectifPedagogique(o.id)}>
                      <Clear />
                    </IconButton>
                  </Box>
                  {lignesObjectifPedagogique
                    .filter((l) => {
                      console.log(l.id_objectif, o.id);
                      return l.id_objectif === o.id;
                    })
                    .map((l, li) => (
                      <Box
                        ml={2}
                        key={l.id}
                        mt={1}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Circle style={{ fontSize: 10 }} />
                        <TextField
                          onChange={(e) =>
                            handleChangeLigneObjectifPedagogique(
                              l.id,
                              e.target.value
                            )
                          }
                          sx={{ flex: 1 }}
                          size={"small"}
                          value={l.label}
                          variant={"standard"}
                        />
                        <IconButton
                          onClick={() => deleteLigneObjectifPedagogique(l.id)}
                        >
                          <Clear />
                        </IconButton>
                      </Box>
                    ))}
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </SectionContainer>

      <SectionContainer>
        <Box display={"flex"} justifyContent={"space-between"} borderBottom={1}>
          <Typography variant={"h6"}>Objectifs de la formation:</Typography>
          <Button
            onClick={addObjectifFormation}
            variant={"outlined"}
            color={"primary"}
          >
            <Add />
          </Button>
        </Box>

        {state.objsFormation.map((o, i) => (
          <Box
            p={1}
            component={Paper}
            m={1}
            display={"flex"}
            alignItems={"center"}
            key={o.id}
          >
            <TextField
              value={o.label}
              variant={"standard"}
              sx={{ flex: 1 }}
              onChange={(e) => handleChangeObjectifFormation(i, e.target.value)}
            />
            <IconButton onClick={() => deleteObjectifFormation(o.id)}>
              <Clear />
            </IconButton>
          </Box>
        ))}
      </SectionContainer>

      <SectionContainer>
        <Box borderBottom={1} display={"flex"} justifyContent={"space-between"}>
          <Typography variant={"h6"}>Journées formation:</Typography>
          <Button
            onClick={addJourneeFormation}
            color={"primary"}
            variant={"outlined"}
          >
            <Add />
          </Button>
        </Box>

        <Box>
          {journeesFormations.map((jf) => (
            <Box component={Paper} key={jf.id} m={2} p={2}>
              <Box display={"flex"} alignItems={"center"}>
                <TextField
                  value={jf.label}
                  sx={{ flex: 1 }}
                  variant={"standard"}
                  onChange={(e) =>
                    handleChangeJourneeFormation(jf.id, e.target.value)
                  }
                />
                <IconButton onClick={() => addSectionJournee(jf.id)}>
                  <Add />
                </IconButton>
                <IconButton onClick={() => deleteJourneeFormation(jf.id)}>
                  <Clear />
                </IconButton>
              </Box>
              Sections:
              {sectionsJournee
                .filter((sj) => sj.id_journee === jf.id)
                .map((sj, sj_i) => (
                  <Box
                    m={2}
                    p={3}
                    component={Paper}
                    sx={{ background: "#cec" }}
                    mx={2}
                    key={sj.id}
                  >
                    <Box display={"flex"} alignItems={"center"}>
                      <TextField
                        sx={{ flex: 1 }}
                        onChange={(e) =>
                          handleChangeSectionJournee(sj.id, e.target.value)
                        }
                        variant={"standard"}
                        value={sj.label}
                      />
                      <IconButton onClick={() => addLigneSectionJournee(sj.id)}>
                        <Add />
                      </IconButton>
                      <IconButton onClick={() => deleteSectionJournee(sj.id)}>
                        <Clear />
                      </IconButton>
                    </Box>
                    <Typography sx={{ mt: 2 }}>Lignes:</Typography>

                    {lignesSectionJournee
                      .filter((lsj) => lsj.id_section === sj.id)
                      .map((lsj, lsj_i) => (
                        <Box
                          key={lsj.id}
                          component={Paper}
                          m={2}
                          p={2}
                          sx={{ background: "#fcd" }}
                          display={"flex"}
                        >
                          <TextField
                            sx={{ flex: 1 }}
                            variant={"standard"}
                            value={lsj.label}
                            onChange={(e) =>
                              handleChangeLigneSectionJournee(
                                lsj.id,
                                e.target.value
                              )
                            }
                          />
                          <IconButton
                            onClick={() => deleteLigneSectionJournee(lsj.id)}
                          >
                            <Clear />
                          </IconButton>
                        </Box>
                      ))}
                  </Box>
                ))}
            </Box>
          ))}
        </Box>

        <Box mt={4}>
          <TextField
            fullWidth
            multiline
            rows={10}
            label={"e-mail par défaut"}
            value={formation.email_acceptation}
            onChange={(e) =>
              handleChange("formation", "email_acceptation", e.target.value)
            }
          />
        </Box>
      </SectionContainer>

      <Box display={"flex"} justifyContent={"flex-end"}>
        <Button
          startIcon={<AddOutlined />}
          onClick={resetState}
          color={"primary"}
          variant={"contained"}
        >
          nouveau
        </Button>
        <Button
          style={{ marginLeft: 8 }}
          onClick={submit}
          color={state.formation.id ? "secondary" : "primary"}
          variant={"contained"}
          startIcon={<Save />}
        >
          {state.formation.id ? "Modifier" : "Créer"}
        </Button>
      </Box>
    </Box>
  );
}
