import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    form:{
        display:'flex',
        flexDirection:'column',
        width: '90%',
        "& .MuiFormControl-root":{
            width: '100%',
            flex:1,
            margin:8,
            "& .MuiInputBase-root":{
                backgroundColor: '#f4f4f6'
            }
        }
    }
}))
export default useStyles;
