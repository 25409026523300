import {Divider, Drawer, Hidden, List, ListItem, ListItemIcon, ListItemText, useTheme} from '@material-ui/core';
import {AccountTree, LibraryBooks, ViewCarousel} from '@material-ui/icons';
import React from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import logo from '../../assets/logo.png'
import {modules} from '../../config/constants';

const DrawerContent = ({classes, onNavClicked}) => {
    let {url} = useRouteMatch();
    return (
        <div>
            <div className={classes.toolbar} style={{textAlign: 'center', paddingTop: 12}}>
                <img height='40' src={logo}/>
            </div>

            <Divider/>
            <List>
                <Link style={{color: "black"}} to={`${url}/home-sliders`} onClick={() => onNavClicked('home-sliders')}>
                    <ListItem button>
                        <ListItemIcon>
                            <ViewCarousel/>
                        </ListItemIcon>
                        <ListItemText primary={"Gestion des sliders"}/>
                    </ListItem>
                </Link>
                {
                    modules.map(module => (
                        <Link key={module.label} style={{color: 'black'}} to={`${url}/${module.label}`}
                              onClick={() => onNavClicked(module.title)}>
                            <ListItem button>
                                <ListItemIcon>{module.icon}</ListItemIcon>
                                <ListItemText primary={module.title}/>
                            </ListItem>
                        </Link>
                    ))
                }

             <Link to={`${url}/gestion-formations`} style={{color: 'black'}}>
                 <ListItem button>
                    <ListItemIcon><LibraryBooks/></ListItemIcon>
                     <ListItemText primary={"Gestion des formations"}/>
                 </ListItem>
             </Link>

             <Link style={{color: 'black'}} to={`${url}/gestion-references`}>
                            <ListItem button>
                                <ListItemIcon><AccountTree/></ListItemIcon>
                                <ListItemText primary={"Gestion des références"}/>
                            </ListItem>
                        </Link>

            </List>

        </div>
    )
}


const Sidebar = ({classes, handleDrawerToggle, mobileOpen, window, setTitle}) => {
    const theme = useTheme();
    const container = window !== undefined ? () => window().document.body : undefined;
    return (
        <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">

                <Drawer
                    container={container}
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >

                    <DrawerContent onNavClicked={setTitle} classes={classes}/>
                </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    <DrawerContent onNavClicked={setTitle} classes={classes}/>
                </Drawer>
            </Hidden>
        </nav>
    );
}

export default Sidebar;
