import {API_URL} from "../../config/constants";

export default class TilDataTableService {
    constructor(tableName, relations) {
        this.tableName = tableName;
        this.relationsJoinQuery = relations.length > 0 ? relations.map(relation => ` LEFT JOIN ${relation.table} ON ${this.tableName}.${relation.on} = ${relation.table}.id`) : '';
        this.relationsSelectQuery = relations.length > 0 ? ', ' + relations.map((relation, index) => `${relation.table}.label as ${relation.on}${(index + 1) !== relations.length ? ',' : ' '}`) : '';
    }

    getTableColumns = async () => {
        return await fetch(API_URL + "get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({query: `SHOW COLUMNS FROM ${this.tableName}`}),
        })
            .then((res) => res.json())
            .catch((err) => err);
    };

    getRowByID = async id => {
        return await fetch(API_URL + "get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({query: `SELECT ${this.tableName}.*${this.relationsSelectQuery}  FROM ${this.tableName}${this.relationsJoinQuery} WHERE ${this.tableName}.id LIKE '${id}'`}),
        })
            .then((res) => res.json())
            .then(res => res[0])
            .catch((err) => err);
    }

    getRows = async () => {
        return await fetch(API_URL + "get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({query: `SELECT ${this.tableName}.*${this.relationsSelectQuery}  FROM ${this.tableName}${this.relationsJoinQuery}`}),
        })
            .then((res) => res.json())
            .catch((err) => err);
    };

    getForeignRows = async (tableName) => {
        return await fetch(API_URL + "get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({query: `SELECT * FROM ${tableName}`}),
        })
            .then((res) => res.json())
            .catch((err) => err);
    };

    insertRow = async (row) => {
        const columns = Object.keys(row);
        const values = Object.values(row).map((value) =>
            value === "" ? "0" : `"${value}"`
        );
        const query = `INSERT INTO ${this.tableName} (${columns}) VALUES (${values})`;
        this.logToClient(query)
        return await fetch(API_URL + "create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({query}),
        })
            .then((res) => res.json())
            .catch((err) => err);
    };

    updateRow = async (row) => {
        let values = "";
        Object.keys(row).forEach((key, i) => {
            values += ` ${key} = "${row[key]}" ${
                i + 1 !== Object.keys(row).length ? "," : ""
            }`;
        });
        const query = `UPDATE ${this.tableName} SET ${values} WHERE id like ${row.id}`;
        return await fetch(API_URL + "update", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({query}),
        })
            .then((res) => res.json())
            .catch((err) => err);
    };

    deleteRow = async (id) => {
        const query = `DELETE FROM ${this.tableName} WHERE id like ${id}`;
        return await fetch(API_URL + "delete", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({query}),
        })
            .then((res) => res.json())
            .catch((err) => err);
    };

    getRowFiles = async (id, tableName) => {
        let query = `SELECT * FROM ${tableName}_images WHERE object_id like ${id}`;
        return await fetch(API_URL + 'get', {
            method: 'POST',
            body: JSON.stringify({query}),
            headers:{
                'Content-type': "application/json"
            }
        })
            .then(res => {
                console.log(res)
                return res.json()
            })
            .catch(err => err)
    }

    updateFileData = async data => {
        const {objectID, fileLabel, fileData} = data;
        let query = `UPDATE ${this.tableName}_images SET file_data = '${fileData}' WHERE object_id LIKE ${objectID} AND image LIKE '${fileLabel}'`
        console.log(query)
        return await fetch(API_URL + 'update', {
            method: 'POST',
            body: JSON.stringify({query})
        })
            .then(res => res.json())
            .catch(err => err)
    }

    logToClient = string => {
        // document.querySelector('#logs-container').innerHTML = `<p>${string}</p>`
    }
}
