import * as React from 'react';
import {useState} from 'react';
import {Box, Button, Grid, Tab, Tabs, TextField, Typography} from "@material-ui/core";
import {IMG_URL} from "../../../../config/constants";
import {Send, Warning} from "@material-ui/icons";
import Carousel from "react-material-ui-carousel";
import CrudService from "../../../../service/crud.service";
import AppSnackBar from "../../../../components/snackbar";
import {sendEmail} from "../../../../service/mail";
import AppText from "../../../../components/app-text";
import App from "../../../../App";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }} height={500}>
                    <Box height={'100%'}>{children}</Box>
                </Box>
            )}
        </div>
    );
}



function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const QuoteForm = ({classes, productID}) => {
    const [form, setForm] = useState({product_id: productID, type: "Devis", subject: "Demande de devis"});
    const [openMessage, setOpenMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState(null);
    const crudService = new CrudService();

    const handleFieldChanged = (field, value) => {
        let formWillUpdate = {...form};
        formWillUpdate[field] = value;
        setForm(formWillUpdate)
    }

    const onSubmit = e => {
        e.preventDefault();
        let formWillInsert = {...form};
        delete formWillInsert.type;
        delete formWillInsert.subject;
        crudService.insertRow(formWillInsert, 'clients_requests')
            .then(async res => {
                setMessage(res ? "Your quote request will be processed soon! " : "Error sending your quote");
                setStatus(res ? "success" : "error");
                setOpenMessage(true);
                // setForm({
                //     ...form,
                //     name: "",
                //     last_name: "",
                //     address: "",
                //     phone: "",
                //     post_code: "",
                //     message: "",
                //     email: ""
                // })
                const quoteProduct = await crudService.getQuoteProduct(form.product_id).then(res => res[0]);
                console.log(quoteProduct)
                const product = {
                    label: quoteProduct.label,
                    category: {
                        label: quoteProduct.category_label,
                        target: quoteProduct.target
                    }
                }
                let formWillSendMail = {...form};
                formWillSendMail.product = product;
                sendEmail(formWillSendMail);
            })
    }
    return (
        <>
            <Typography style={{borderBottom: '1px solid'}} variant={'subtitle1'}>
                <AppText text={"fill-info"}/>
            </Typography>

            <form onSubmit={onSubmit} className={classes.form}>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={4}>
                        <TextField
                            value={form.name}
                            required
                            onChange={e => handleFieldChanged('name', e.target.value)}
                            variant={'outlined'} label={<AppText text={"name-only"}/>}/>
                    </Grid>

                    <Grid item xs={6} md={4}>
                        <TextField
                            value={form.last_name}
                            required
                            onChange={e => handleFieldChanged('last_name', e.target.value)}
                            variant={'outlined'} label={<AppText text={'last-name'}/> }/>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            value={form.email}
                            required
                            type={'email'}
                            onChange={e => handleFieldChanged('email', e.target.value)}
                            variant={'outlined'} label={<AppText text={'e-mail'}/> }/>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            value={form.address}
                            required
                            onChange={e => handleFieldChanged('address', e.target.value)}
                            variant={'outlined'} label={<AppText text={'Address'}/> }/>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <TextField
                            value={form.phone}
                            required
                            type={"number"}
                            onChange={e => handleFieldChanged('phone', e.target.value)}
                            variant={'outlined'} label={<AppText text={'Phone'}/> }/>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <TextField
                            value={form.post_code}
                            required
                            onChange={e => handleFieldChanged('post_code', e.target.value)}
                            type={'number'}
                            variant={'outlined'} label={<AppText text={'post-code'}/> }/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={form.message}
                            required
                            onChange={e => handleFieldChanged('message', e.target.value)}
                            multiline rows={4} label={"Message"} variant={"outlined"}/>
                    </Grid>

                    <Grid item xs>
                        <Box textAlign={'right'}>
                            <Button type={"submit"} size={'large'} startIcon={<Send/>} variant={'outlined'} color={'primary'}>
                                <AppText text={"send"}/>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
            <AppSnackBar open={openMessage} message={message} handleClose={() => setOpenMessage(false)} status={status}/>

        </>

)
}


export default function ProductTabs({data, images, classes}) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={<AppText text={"technical-file"}/> } {...a11yProps(0)} />
                    <Tab label="Images" {...a11yProps(1)} />
                    <Tab label="Video" {...a11yProps(2)} />
                    <Tab label={<AppText text={"quote"}/> } {...a11yProps(3)} />

                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                {
                    (data.file !== "0" ?

                                <iframe  src={IMG_URL+data.file} frameborder="0" width={'100%'} height={'100%'}/>
                            : <p><Warning/> <AppText text={"Product does not have a file"}/> </p>
                    )
                }
            </TabPanel>
            <TabPanel value={value} index={1}>
                {
                    (
                        images.length === 0 ?
                            <Box textAlign={'center'}>
                                <img height={300} src={IMG_URL + data.image} alt={"img"}/>
                            </Box>
                            :
                            <Carousel navButtonsAlwaysVisible animation={'slide'} className={classes.imageCarousel}>
                                {
                                    images.map((image,i) => (
                                        <Box textAlign={'center'}>
                                            <img height={300} src={IMG_URL + image.image} alt={"img"}/>
                                        </Box>
                                    ))
                                }
                            </Carousel>
                    )
                }
            </TabPanel>
            <TabPanel value={value} index={2}>
                {
                    (data.video !== "0" ? <video controls autoPlay width="80%" height="auto">
                        <source src={IMG_URL+data.video} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video> : <p><Warning/> Product does not have a video</p>)
                }
            </TabPanel>
            <TabPanel value={value} index={3}>
                {
                    data.lien_devis !== "" ?
                    <p><AppText text="subscription-text"/> <a target='_blank' rel="noreferrer" href={data.lien_devis}><AppText text="link"/></a></p>
                    :
                    <QuoteForm productID={data.id} classes={classes}/>
                }
            </TabPanel>
        </Box>
    );
}
