import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Typography,
    Zoom,
    useMediaQuery
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Clear, Info, Person, Pets, Search } from "@material-ui/icons";
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import AppText from "../../../../components/app-text";
import LandingSectionTitle from "../../../../components/landingSectionTitle";
import { IMG_URL } from "../../../../config/constants";
import CrudService from "../../../../service/crud.service";
import ProductTabs from "./ProductTabs";
import useStyles from "./styles";



const ProductModal = ({data, onCloseClicked}) => {
    const classes = useStyles();
    const [images, setImages] = useState([]);
    const service = new CrudService();
    useEffect(() => {
        service.getRowsBy("products_images", "object_id", data.id)
            .then(res => setImages(res))
    }, [])
    return (
        <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            zIndex={9999}
            position={'fixed'}
            height={'100vh'}
            width={'100%'}
            top={0}
            left={0}
            style={{
                background: 'rgba(0,0,0,.2)'
            }}
        >

            <Paper className={classes.modalContent}>
                <Box height={'100%'} display={'flex'} flexDirection={'column'}>
                    {/*    modal header*/}
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={1} ml={6}>
                        <Typography variant={'subtitle1'}>{data.label}</Typography>
                        <IconButton onClick={onCloseClicked}>
                            <Clear style={{color: 'red'}}/>
                        </IconButton>
                    </Box>


                    {/*    modal content*/}
                    <Box flex={1}>
                        <Grid container>
                            {/*product image && details*/}
                            <Grid item xs={12} md={4}>
                                <Box m={1} display={'flex'} alignItems={'center'}
                                     flexDirection={'column'}>
                                    <img width={'50%'} src={IMG_URL + data.image} alt="product-img"/>
                                    <Typography variant={"body1"}>
                                        {localStorage.getItem("lang") === "en" ? data.description : data.description_fr}
                                    </Typography>
                                </Box>
                            </Grid>

                            {/*product data*/}
                            <Grid item xs={12} md={8}>
                                <Box m={1} display={'flex'} alignItems={'center'}
                                     flexDirection={'column'}>
                                    <ProductTabs data={data} images={images} classes={classes}/>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box m={2} display={'flex'} justifyContent={'flex-end'}>
                        <Button color={'secondary'} onClick={onCloseClicked}>
                            <AppText text={"Close"}/>
                        </Button>
                    </Box>
                </Box>

            </Paper>

        </Box>
    )
}

const ProductTitle = ({title, subTitle}) => {
    return (
        <Box position={'relative'} display={'flex'} alignItems={'center'} flexDirection={'column'}>

            <Typography variant={'h6'}>
                {title}
            </Typography>
            <Box width={64} height={2} style={{background: 'rgb(38,200,182)'}}/>
            {/*<Typography variant={'h6'} style={{fontWeight: 'lighter'}}>*/}
            {/*    {subTitle}*/}
            {/*</Typography>*/}
        </Box>
    )
}



const Product = ({data}) => {
    const classes = useStyles();
    const [showButtons, setShowButtons] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [detailsType, setDetailsType] = useState("");
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        setShowModal(detailsType !== "")
    }, [detailsType])
    return (
        <Grid item xs={6} md={3}>
            <Box
                onMouseEnter={() => setShowButtons(true)}
                onMouseLeave={() => setShowButtons(false)}
                textAlign={'center'}
                style={{cursor: 'pointer'}}
            >
                <Paper
                    style={{borderRadius: 24, overflow: 'hidden', height: '100%', position: 'relative', padding: 8}}>
                    <img height={180} onLoad={() => setImageLoaded(true)} width={'100%'} src={IMG_URL + data.image}
                         alt=""/>

                    {
                        !imageLoaded &&
                        <Box textAlign={'center'}>
                            <CircularProgress/>
                        </Box>
                    }
                    <ProductTitle title={`${data.label}`}/>
                    {/*    overlay buttons*/}

                    <Zoom in={showButtons}>
                        <Box
                            className={classes.overlayButtons}
                            position={'absolute'}
                            top={0}
                            left={0}
                            height={'100%'}
                            width={'100%'}
                            style={{
                                background: 'rgba(0,0,0,.6)'
                            }}
                            justifyContent={'center'}
                            display={'flex'}
                            alignItems={'center'}
                        >
                            <Button onClick={() => setDetailsType('d')} startIcon={<Info/>} variant={'outlined'}
                                    style={{color: 'white'}}>
                                Details
                            </Button>
                        </Box>
                    </Zoom>

                </Paper>


            </Box>
            {/*    modal*/}

            {showModal &&
            <ProductModal
                onCloseClicked={() => setDetailsType("")}
                data={data}
                type={detailsType}
            />}


        </Grid>
    )
}

const Products = ({category = "", subCategory = ""}) => {
    const service = new CrudService();
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [items, setItems] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        // if (categories.length > 0 && subCategory !== "")
        //     setSelectedCategory(categories.find(category =>  category.id === subCategory))
        service.getRowsBy('products', 'category_id', subCategory)
                .then(res => setItems(res))
    }, [subCategory])

    // fetch categories
    useEffect(() => {
        service.getRowsByTableName('products_categories')
            .then(res => setCategories(res.filter(c => c.target === category)))
        setItems([])

        return () => {
            setCategories([])
        }
    }, [category])
    // scroll when page is loaded
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

    }, [])
    // fetch items when category changes
    useEffect(() => {
        if(selectedCategory)
        if (selectedCategory.id) {
            service.getRowsBy('products', 'category_id', selectedCategory.id)
                .then(res => setItems(res))
        }
    }, [selectedCategory])
    // filter items when search value changes
    useEffect(() => {
        if (searchValue.trim() === "")
            setFilteredItems([])
        else
            setFilteredItems(items.filter(item =>
                item.label.toLowerCase().includes(searchValue.toLowerCase())
            ))
    }, [searchValue])
    return (
        <Container>
            <Box borderBottom={1} borderColor={'rgb(38,200,182)'} display={'flex'} justifyContent={'space-between'}
                 alignItems={'center'}>
                <Grid container>
                    <Grid item md={6} xs={12}>
                        <LandingSectionTitle
                            activateOnScrollAnimation={false}
                            my={3}  title={'our-products'}
                            alignItems={'flex-start'}
                            subTitle={'x'}/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                   <Box mt={3}>
                   <Typography variant={'h6'} style={{fontWeight: '300'}}>
                   <AppText text={"our-products-description"}/>
                   </Typography>
                   </Box>

                    </Grid>
                </Grid>
            </Box>
           
            <Box mt={4}>
                <Grid container spacing={2}>
                    <Grid item lg={3} xs={12} sm={12}>
                        <Paper>
                            <Box p={1}>
                                <TextField
                                
                                    value={searchValue}
                                    onChange={e => setSearchValue(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <Search/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    style={{width: '100%', zIndex: 0}} label={localStorage.getItem("lang") === "en" ? 'Search Product' : 'Recherche produits'} variant={'outlined'}/>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item lg={9} xs={12} sm={12}>
                        <Paper>
                            <Box p={1}>
                                <Grid container spacing={2}>
                                    {
                                        items.length === 0 || (searchValue.trim() !== "" && filteredItems.length === 0) ?
                                            <Box width={'100%'} p={16} textAlign={'center'}>
                                                <h3>0 Items</h3>
                                            </Box>
                                            :
                                            (searchValue.length > 0 ? filteredItems : items).map((product, index) => (
                                                <Product data={product} key={index}/>
                                            ))

                                    }
                                </Grid>
                            </Box>
                        </Paper>
                    </Grid>

                </Grid>
            </Box>


        </Container>
    )
}

export default Products;

export { ProductModal };

