import {
  Box,
  Collapse,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { ExpandLess, ExpandMore, Menu } from "@material-ui/icons";
import React, { Fragment, Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HashLink as Link } from "react-router-hash-link";
import en from "../../../assets/images/en.png";
import fr from "../../../assets/images/fr.png";
import logo from "../../../assets/logo.svg";
import AppText from "../../../components/app-text";
import CrudService from "../../../service/crud.service";
import { useParams } from "react-router-dom";
import { Circle } from "@mui/icons-material";
const renderLogo = (
  <Box
    display={"flex"}
    justifyContent={"center"}
    alignItems={"center"}
    maxHeight={67}
    overflow={"hidden"}
    style={{ marginLeft: -40 }}
  >
    <img src={logo} alt={"logo"} style={{ width: "95%" }} />
  </Box>
);
const renderMobileLogo = (
  <Box
    display={"flex"}
    justifyContent={"center"}
    alignItems={"center"}
    maxHeight={60}
    overflow={"hidden"}
  >
    <img src={logo} alt={"logo"} style={{ height: 250 }} />
  </Box>
);

const ComingSoonComponent = () => (
  <div
    style={{
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 64,
    }}
  >
    <h3
      style={{
        background: "black",
        color: "white",
        padding: 16,
        textDecoration: "underline",
      }}
    >
      Coming soon
    </h3>
  </div>
);

const RenderMenu = ({
  medCategories = [],
  vetCategories = [],
  envCategories = [],
  subCategories = [],
  target,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      paddingY={2}
      alignItems={"center"}
    >
      {renderLogo}
      {/*<ul className={styles.navLinks}>*/}
      <ul className={"dropdown"}>
        <li
          onClick={() =>
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
          }
        >
          <Link to={"/"}>
            {/* <Home /> */}
            <AppText text={"HOME"} />
          </Link>
        </li>
        <li style={{ position: "unset" }}>
          <Link to={"/products/vet"}>
            <AppText text={"b-vet"} />
            <div className="wide-dropdown">
              <Container>
                <Grid container spacing={6}>
                  {vetCategories.length > 0 ? (
                    vetCategories.map((c) => (
                      <Grid key={c.id} item md={3}>
                        <p
                          style={{
                            // color: "var(--primary)",
                            color: "rgb(18, 46, 69)",
                            marginBottom: 8,
                            fontWeight: "700",
                            fontSize: 15,
                          }}
                        >
                          {localStorage.getItem("lang") === "en"
                            ? c.label
                            : c.label_fr}
                        </p>

                        {subCategories
                          .filter((sc) => sc.category_id === c.id)
                          .map((sc) => (
                            <Link
                              to={`/products/vet/${sc.id}`}
                              className="underline-on-hover"
                              style={{
                                color: "#000",
                                fontSize: 14,
                                marginTop: 8,
                                marginLeft: -4,
                              }}
                            >
                              <Circle style={{ height: 7 }} />
                              <span style={{ marginLeft: -4 }}>
                                {" "}
                                {localStorage.getItem("lang") === "en"
                                  ? sc.label
                                  : sc.label_fr}
                              </span>
                            </Link>
                          ))}
                      </Grid>
                    ))
                  ) : (
                    <ComingSoonComponent />
                  )}
                </Grid>
              </Container>
            </div>
          </Link>
        </li>
        <li style={{ position: "unset" }}>
          <Link to={"/products/med"}>
            <AppText text={"b-med"} />
            <div className="wide-dropdown">
              <Container>
                <Grid container spacing={3}>
                  {medCategories.length > 0 ? (
                    medCategories.map((c) => (
                      <Grid key={c.id} item md={3}>
                        <p
                          style={{
                            color: "var(--primary)",
                            marginBottom: 8,
                            fontWeight: "500",
                          }}
                        >
                          {localStorage.getItem("lang") === "en"
                            ? c.label
                            : c.label_fr}
                        </p>

                        {subCategories
                          .filter((sc) => sc.category_id === c.id)
                          .map((sc) => (
                            <Link
                              to={`/products/med/${sc.id}`}
                              className="underline-on-hover"
                              style={{ color: "#000", fontSize: 14 }}
                            >
                              <Circle style={{ height: 5 }} />
                              {localStorage.getItem("lang") === "en"
                                ? sc.label
                                : sc.label_fr}
                            </Link>
                          ))}
                      </Grid>
                    ))
                  ) : (
                    <ComingSoonComponent />
                  )}
                </Grid>
              </Container>
            </div>
          </Link>
        </li>
        <li style={{ position: "unset" }}>
          <Link to={"/products/env"}>
            <AppText text={"b-env"} />
            <div className="wide-dropdown">
              <Container>
                <Grid container spacing={3}>
                  {envCategories.length > 0 ? (
                    envCategories.map((c) => (
                      <Grid key={c.id} item md={3}>
                        <p
                          style={{
                            color: "var(--primary)",
                            marginBottom: 8,
                            fontWeight: "500",
                          }}
                        >
                          {localStorage.getItem("lang") === "en"
                            ? c.label
                            : c.label_fr}
                        </p>

                        {subCategories
                          .filter((sc) => sc.category_id === c.id)
                          .map((sc) => (
                            <Link
                              to={`/products/env/${sc.id}`}
                              className="underline-on-hover"
                              style={{ color: "#000", fontSize: 14 }}
                            >
                              <Circle style={{ height: 5 }} />
                              {localStorage.getItem("lang") === "en"
                                ? sc.label
                                : sc.label_fr}
                            </Link>
                          ))}
                      </Grid>
                    ))
                  ) : (
                    <ComingSoonComponent />
                  )}
                </Grid>
              </Container>
            </div>
          </Link>
        </li>

        {/* <Link to="/#our-categories">
                    <li><Button startIcon={<ViewList/>}><AppText text={"OUR CATEGORIES"}/> </Button></li>
                </Link> */}
        {/* <Link to="/#our-trainings">
                    <li><Button startIcon={<LibraryBooks/>}><AppText text={"OUR TRAININGS"}/> </Button></li>
                </Link> */}

        <li>
          {/* <Info /> */}
          <AppText text={"ABOUT"} />
          <ul>
            <Link to="/#about-section">
              <li>
                <AppText text={"who-we-are"} />
              </li>
            </Link>

            <Link to="/#our-references">
              <li>
                {/* <AccountTree /> */}
                <AppText text={"references-title"} />
              </li>
            </Link>
            <Link to="/#our-partners">
              <li>
                {/* <People /> */}
                <AppText text={"OUR PARTNERS"} />
              </li>
            </Link>
          </ul>
        </li>

        <Link to={"/#contact-us"}>
          <li>
            {/* <RecentActors /> */}
            <AppText text={"Contact"} />
          </li>
        </Link>

        <Box
          onClick={() => {
            if (localStorage.getItem("lang") === "en")
              localStorage.setItem("lang", "fr");
            else localStorage.setItem("lang", "en");
            window.location.reload();
          }}
          sx={{ cursor: "pointer" }}
          display="flex"
          alignItems={"center"}
        >
          <img
            height={24}
            src={localStorage.getItem("lang") === "en" ? fr : en}
            alt={"lng"}
          />
        </Box>
        {/*<li>*/}
        {/*    <div id="google_translate_element"/>*/}
        {/*</li>*/}
      </ul>
    </Box>
  );
};

const RenderMobileMenu = ({
  medCategories = [],
  vetCategories = [],
  envCategories = [],
  subCategories = [],
  target,
}) => {
  const [open, setOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [vetOpen, setVetOpen] = useState(false);
  const [medOpen, setMedOpen] = useState(false);
  const [envOpen, setEnvOpen] = useState(false);
  const classes = useStyles();
  const handleMenu = () => {
    setOpen(!open);
  };
  return (
    <Box maxHeight={"70vh"} overflow={"auto"}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        paddingY={2}
        alignItems={"center"}
      >
        {renderMobileLogo}
        <IconButton
          edge={"start"}
          onClick={handleMenu}
          aria-controls="simple-menu"
          aria-haspopup="true"
        >
          <Menu />
        </IconButton>
      </Box>
      <Collapse in={open}>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
        >
          <ListItem button component={Link} to={"/"} onClick={handleMenu}>
            {/* <ListItemIcon>
              <Home />
            </ListItemIcon> */}
            <ListItemText primary={<AppText text={"Home"} />} />
          </ListItem>

          <ListItem button onClick={() => setVetOpen(!vetOpen)}>
            <ListItemText primary={<AppText text={"b-vet"} />} />
            {vetOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={vetOpen} unmountOnExit timeout={"auto"}>
            <List style={{ marginLeft: 16 }}>
              {vetCategories.map((c) => (
                <Fragment key={c.id}>
                  <ListItem style={{ paddingBottom: 0 }}>
                    <ListItemText
                      style={{ color: "rgb(36, 199, 183)" }}
                      // style={{ color:"rgb(18, 46, 69)"}}
                      primary={
                        localStorage.getItem("lang") === "en"
                          ? c.label
                          : c.label_fr
                      }
                    />
                  </ListItem>
                  <Collapse in>
                    <List style={{ padding: 0 }}>
                      {subCategories
                        .filter((sc) => sc.category_id === c.id)
                        .map((sc) => (
                          <ListItem
                            style={{ padding: 0, paddingLeft: 32 }}
                            key={sc.id}
                            component={Link}
                            className={classes.nested}
                            onClick={handleMenu}
                            to={`/products/vet/${sc.id}`}
                          >
                            {/* <ListItemIcon>
                              <Circle style={{ height: 5 }} />
                            </ListItemIcon> */}
                            <ListItemText
                              style={{ color: "#000" }}
                              primary={
                                localStorage.getItem("lang") === "en"
                                  ? sc.label
                                  : sc.label_fr
                              }
                            />
                          </ListItem>
                        ))}
                    </List>
                  </Collapse>
                </Fragment>
              ))}
            </List>
          </Collapse>

          <ListItem button onClick={() => setMedOpen(!medOpen)}>
            <ListItemText primary={<AppText text={"b-med"} />} />
            {medOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={medOpen} unmountOnExit timeout={"auto"}>
            <List>
              {medCategories.map((c) => (
                <Fragment key={c.id}>
                  <ListItem>
                    <ListItemText
                      primary={
                        localStorage.getItem("lang") === "en"
                          ? c.label
                          : c.label_fr
                      }
                    />
                  </ListItem>
                  <Collapse in>
                    <List>
                      {subCategories
                        .filter((sc) => sc.category_id === c.id)
                        .map((sc) => (
                          <ListItem
                            key={sc.id}
                            component={Link}
                            className={classes.nested}
                            onClick={handleMenu}
                            to={`/products/med/${sc.id}`}
                          >
                            <ListItemIcon>
                              <Circle style={{ height: 5 }} />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                localStorage.getItem("lang") === "en"
                                  ? c.label
                                  : c.label_fr
                              }
                            />
                          </ListItem>
                        ))}
                    </List>
                  </Collapse>
                </Fragment>
              ))}
            </List>
          </Collapse>
          <ListItem button onClick={() => setEnvOpen(!envOpen)}>
            <ListItemText primary={<AppText text={"b-env"} />} />
            {envOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={envOpen} unmountOnExit timeout={"auto"}>
            <List>
              {envCategories.map((c) => (
                <Fragment key={c.id}>
                  <ListItem>
                    <ListItemText
                      primary={
                        localStorage.getItem("lang") === "en"
                          ? c.label
                          : c.label_fr
                      }
                    />
                  </ListItem>
                  <Collapse in>
                    <List>
                      {subCategories
                        .filter((sc) => sc.category_id === c.id)
                        .map((sc) => (
                          <ListItem
                            key={sc.id}
                            component={Link}
                            className={classes.nested}
                            onClick={handleMenu}
                            to={`/products/env/${sc.id}`}
                          >
                            <ListItemIcon>
                              <Circle style={{ height: 5 }} />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                localStorage.getItem("lang") === "en"
                                  ? c.label
                                  : c.label_fr
                              }
                            />
                          </ListItem>
                        ))}
                    </List>
                  </Collapse>
                </Fragment>
              ))}
            </List>
          </Collapse>
          <ListItem button onClick={() => setAboutOpen(!aboutOpen)}>
            <ListItemText primary={<AppText text={"ABOUT"} />} />
            {aboutOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={aboutOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to={"#about-section"}>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={handleMenu}
                >
                  <ListItemText
                    style={{ color: "#000" }}
                    primary={<AppText text="who-we-are" />}
                  />
                </ListItem>
              </Link>
              <Link to={"#our-references"}>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={handleMenu}
                >
                  <ListItemText
                    style={{ color: "#000" }}
                    primary={<AppText text="references-title" />}
                  />
                </ListItem>
              </Link>
              <Link to={"#our-partners"}>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={handleMenu}
                >
                  <ListItemText
                    style={{ color: "#000" }}
                    primary={<AppText text="OUR PARTNERS" />}
                  />
                </ListItem>
              </Link>
            </List>
          </Collapse>

          <ListItem
            button
            onClick={handleMenu}
            component={Link}
            to="/#contact-us"
          >
            <ListItemText primary={<AppText text={"CONTACT"} />} />
          </ListItem>
          <Box
            p={2}
            onClick={() => {
              if (localStorage.getItem("lang") === "en")
                localStorage.setItem("lang", "fr");
              else localStorage.setItem("lang", "en");
              window.location.reload();
            }}
            display="flex"
            alignItems={"center"}
          >
            <img
              height={24}
              src={localStorage.getItem("lang") === "en" ? fr : en}
              alt={"lng"}
            />
          </Box>
        </List>
      </Collapse>
    </Box>
  );
};

const Navbar = ({ target }) => {
  const theme = useTheme();
  const isMobile = window.innerWidth < 1053;
  const [medCategories, setMedCategories] = useState([]);
  const [vetCategories, setVetCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const service = new CrudService();

  useEffect(() => {
    service.getRowsByTableName("products_categories").then((res) => {
      setVetCategories(res.filter((category) => category.target === "Vet"));
      setMedCategories(res.filter((category) => category.target === "Med"));
    });
    service
      .getRowsByTableName("products_sub_categories")
      .then((res) => setSubCategories(res));
  }, []);
  return (
    <nav id={"header"}>
      <Paper style={{ borderRadius: 0 }}>
        <Container>
          {isMobile ? (
            <RenderMobileMenu
              medCategories={medCategories}
              vetCategories={vetCategories}
              subCategories={subCategories}
              target={target}
            />
          ) : (
            <Suspense fallback={<></>}>
              <RenderMenu
                medCategories={medCategories}
                vetCategories={vetCategories}
                subCategories={subCategories}
                target={target}
              />
            </Suspense>
          )}
        </Container>
      </Paper>
    </nav>
  );
};

export default Navbar;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },

  menuDrop: {
    "& .MuiPaper-root": {
      top: "110px!important",
      width: 252,
    },
  },
}));
