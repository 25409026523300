import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import frToEnMapper from '../../config/frToEnMapper';

export default function Confirmation({open, handleClose, agreeClicked, label, dbTableName}) {


  return (
    <div>
     
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation de suppression"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Voulez vous vraiment supprimer ce  {frToEnMapper(dbTableName)} {" #" + label} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={agreeClicked} color="secondary">
            OUI
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            NON
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
