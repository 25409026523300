import React, {useState} from "react";
import {
    Box,
    Button,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import {IMG_URL} from "../../config/constants";
import {AttachFile, Delete, Edit, FileCopy} from "@material-ui/icons";
import {green, red, yellow} from "@material-ui/core/colors";

function TilTableRow({row, uploadables, setRowWillDelete, setRowWillUpdate, handleOpenUploadable, relations}) {
    console.log(relations)
    const [open, setOpen] = useState(false);
    return (
        <>
            <TableRow key={row.id}>
                {
                    uploadables.filter(u => u.displayInTable).map(u => (
                        <TableCell key={u.field}>
                            {
                                <img style={{height: 60}} src={IMG_URL + row[u.field]} alt={'img'}/>
                            }
                        </TableCell>
                    ))
                }
                {Object.values(row).map((value, i) => {
                    const column = uploadables.find(uploadable => uploadable.field === Object.keys(row)[i]);

                    if (!column)

                        return (<TableCell key={i}>

                            {value}

                        </TableCell>)

                })}
                <TableCell>
                    <Box display={'flex'}>
                        <IconButton onClick={() => setRowWillUpdate(row)}>
                            <Edit style={{color: yellow[800]}}/>
                        </IconButton>
                        <IconButton onClick={() => setRowWillDelete(row)}>
                            <Delete style={{color: red[600]}}/>
                        </IconButton>
                        {
                            uploadables.length > 0 &&
                            <IconButton onClick={() => setOpen(!open)}>
                                <FileCopy style={{color: green[600]}}/>
                            </IconButton>
                        }
                    </Box>
                </TableCell>
            </TableRow>
            {/*    Row collapse */}
            <TableRow>
                <TableCell style={{padding: 0}} colSpan={6}>
                    <Collapse
                        in={open}
                        timeout="auto" unmountOnExit>
                        <Box style={{background: '#d9f8f1'}} padding={1} borderRadius={4}>
                            <Typography variant="h6" gutterBottom component="div">
                                Gestion des fichiers
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        {
                                            uploadables.map(u => (
                                                <TableCell key={u.field}>{u.field}</TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        uploadables.map((u,i) => (
                                            <TableCell key={i}>
                                                            <Button
                                                                onClick={() => handleOpenUploadable(u.field, row.id)}
                                                                startIcon={<AttachFile/>}
                                                                 color={'secondary'}>Gérer</Button>
                                                         </TableCell>
                                        ))
                                    }
                                    {/*{*/}
                                    {/*    uploadables.map((uploadable, i) => {*/}
                                    {/*        const index = Object.keys(row).findIndex(column => uploadables[0].field === column);*/}
                                    {/*        const column = uploadables.find(uploadable => {*/}
                                    {/*            return uploadable.field === Object.keys(row)[i + index]*/}
                                    {/*        });*/}
                                    {/*        return (*/}
                                    {/*            <TableCell key={i}>*/}
                                    {/*                <Button*/}
                                    {/*                    onClick={() => handleOpenUploadable(column.field, row.id)}*/}
                                    {/*                    startIcon={<AttachFile/>}*/}
                                    {/*                    color={'secondary'}>Gérer</Button>*/}
                                    {/*            </TableCell>*/}
                                    {/*        )*/}
                                    {/*    })*/}
                                    {/*}*/}

                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default TilTableRow;
