const frToEnMapper = (word, plural = false) => {
    let wordWillReturn = "";
    switch (word) {
        case "product":
            wordWillReturn = "produit";
            break;
        case "products":
            wordWillReturn = "produit";
            break;
        case "date_created":
            wordWillReturn = "Creation";
            break;
        case "date_updated":
            wordWillReturn = "Modification";
            break;
        case "products_categories":
            wordWillReturn = "catégories des produit";
            break;
        case "partners":
            wordWillReturn = "partenaire";
            break;
        case "category_id":
            wordWillReturn = "Catégorie";
            break;
        case "phone":
            wordWillReturn = "tel";
            break;
        case "name":
            wordWillReturn = "Prénom";
            break;
        case "last_name":
            wordWillReturn = "Nom";
            break;
        case "contacts":
            wordWillReturn = "contact";
            break;
        case "clients_requests":
            wordWillReturn = "demandes client";
            break;
        default:
            wordWillReturn = word;
            break;
    }
    return `${wordWillReturn}${plural ? "s" : ""}`;
};

export default frToEnMapper;
