export const getDateFromObject = dateObject => {
    return dateObject instanceof Date ? dateObject : new Date(dateObject);
}

export const dateToInput = dateObject => {
    const date = getDateFromObject(dateObject)
    return date.getFullYear() + '-' + formatMonth(date.getMonth()) + '-' + formatDay(date.getDate());
}

export const formatMonth = month => {
    return month < 9 ? '0' + (month + 1) : (month + 1);
}

export const formatDay = day => {
    return day < 10 ? '0' + day : day;
}
