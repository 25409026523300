import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    topBarTitle:{
        display:'flex',
        "& .MuiSvgIcon-root":{
            fontSize: '1.1em',
            color: 'rgb(38, 200, 182)'
        }
    },
    icons:{
        "& svg":{
            color: 'rgb(38,200,182)',
            marginLeft: 8,
            cursor: 'pointer',
            "& :hover":{
                color: 'white'
            }
        }
    }
}))
export default useStyles;
