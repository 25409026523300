import React from 'react';
import {Box, Container, Typography} from "@material-ui/core";
import titleBg from "../../assets/images/heading-icon.png";
import {translation} from "../../config/i18n";

const LandingSectionTitle = ({title, subTitle, my = 8, alignItems = 'center', activateOnScrollAnimation = true}) => {
    const translatedTitle = translation.find(t => t.key.toLowerCase() === title.toLowerCase());
    const translatedSubTitle = translation.find(t => t.key.toLowerCase() === subTitle.toLowerCase());

    return (
        <Box data-aos={activateOnScrollAnimation && "zoom-in"} position={'relative'} my={my} display={'flex'}
             alignItems={alignItems} flexDirection={'column'}>
            <img className={"title-bg rotate-scale-up"} src={titleBg} alt={""}/>
            <Typography variant={'h4'}>
                {translatedTitle ? localStorage.getItem("lang") === "en" ? translatedTitle.en : translatedTitle.fr : "..."}
            </Typography>
            <Box my={2} width={64} height={2} style={{background: 'rgb(38,200,182)'}}/>
            <Container>
                <Typography align={'center'} variant={'h6'} style={{fontWeight: '300'}}>
                    {translatedSubTitle ? localStorage.getItem("lang") === "en" ? translatedSubTitle.en : translatedSubTitle.fr : ""}
                </Typography>
            </Container>
        </Box>
    )
}

export default LandingSectionTitle;

