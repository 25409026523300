import {useStyles} from "./styles";

function TilBackDrop({children, open}) {
    const classes = useStyles();
    return (
       <>
           {
               open &&
               <div className={classes["TilBackdrop-root"]}>
                   {children}
               </div>
           }</>
    );
}

export default TilBackDrop;
