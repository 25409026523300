import LandingSectionTitle from "../../../../components/landingSectionTitle";
import {Box, Container, Grid, Typography} from "@material-ui/core";
import oneHealth from '../../../../assets/images/one-health.jpg';
import {FooterTitle} from "../../../layout/footer";
import range from '../../../../assets/images/shopping-list.png';
import vision from '../../../../assets/images/vision.png';
import assurance from '../../../../assets/images/assurance.png';
import shuttle from '../../../../assets/images/shuttle.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from "react";
import AppText from "../../../../components/app-text";
import App from "../../../../App";

export const About = () => {

    useEffect(() => {
        AOS.init({
            duration: 500
        });
    }, [])

    return (
        <section id={"about-section"}>
            <LandingSectionTitle title={"about-section-title"}
                                 subTitle={"about-sub-title"}/>

            <Container>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={5}>
                        <img data-aos="flip-up" src={oneHealth} alt={'one health'} width={'100%'}/>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Box py={4} px={1}  data-aos="zoom-in">
                            <Typography variant={'h6'} align={'justify'}>
                                <AppText text={"one-health"}/>
                            </Typography>
                        </Box>
                    </Grid>

                </Grid>
            </Container>

            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={3}>
                        <FooterTitle label={"product-range-title"} titleColor={"black"}
                                     text={<ul>
                                         <li> <AppText text={"laboratory-equipment"}/> </li>
                                         <li> <AppText text={"intensive-care-equipment"}/></li>
                                         <li><AppText text={"operating-room"}/></li>
                                         <li><AppText text={"medical-imaging"}/></li>
                                         <li><AppText text={"endoscopy"}/></li>
                                     </ul>}
                                     icon={range}

                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FooterTitle label={"our-vision"} titleColor={"black"}
                                     text={<Typography>
                                         <AppText text={"our-vision-text"}/>
                                     </Typography>}
                        icon={vision}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FooterTitle label={"our-mission"} titleColor={"black"}
                                     text={<Typography>
                                         <AppText text={"our-mission-text"}/>
                                     </Typography>}
                                     icon={shuttle}

                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FooterTitle label={"assuring-service"} titleColor={"black"}
                                     text={<Typography>
                                         <AppText text={"assuring-service-text"}/>
                                     </Typography>}
                                     icon={assurance}

                        />
                    </Grid>
                </Grid>
            </Container>
        </section>
    );
};
