export const sendEmail = (data) => {
    let formData = new FormData();
    formData.append('email',data.email);
    formData.append('subject',  data.subject)
    formData.append('message', data.message);
    formData.append('name', data.raison_social ? data.raison_social : (data.name + " " + data.last_name))
    formData.append('phone', data.phone)
    formData.append('address', data.address)
    formData.append('postCode', data.post_code);
   formData.append('type', data.type);
    if(data.product)
        formData.append('quote', `
             <h1>***** produit: ${data.product.label} || categorie:  ${data.product.category.label} || cible: ${data.product.category.target} ******</h1>
        `);

    fetch('https://www.evocare.tn/API/mail.php', {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(res => {
            console.log(res)})
        .catch(error => console.info(error))

}

export const sendEmailInscription = (data) => {
    let formData = new FormData();
    formData.append('email',data.email);
    formData.append('message', data.message);
    formData.append('name', data.nom_prenom)
    formData.append('phone', data.telephone)
    formData.append('address', data.adresse)

    fetch('https://www.evocare.tn/API/inscription_mail.php', {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(res => {
            console.log("inscription res: ",res)})
        .catch(error => console.info(error))

}
