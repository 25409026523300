import {Box, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography} from "@material-ui/core";
import {DataGrid} from "@mui/x-data-grid";
import {useEffect, useState} from "react";
import {Add, Delete, Edit, List} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import TilBackDrop from "../../../components/TilBackDrop/TilBackDrop";
import FormulaireFormation from "./form";
import {executeQuery} from "../../../service/crud.service";
import {IconButton} from "@mui/material";
import Inscriptions from "./inscriptions";



export default function ListeFormation(){
    const columns = [
        {
            field: "id",
            headerName: "#",
            renderCell: ({row}) => <Box display={"flex"}>
                <IconButton
                onClick={() => {
                    setFormationWillUpdate(row);
                    setShowForm(true)
                }}
                >
                    <Edit style={{color: "#fcd456"}}/>
                </IconButton>
                <IconButton onClick={() => {
                    executeQuery("DELETE FROM formation WHERE id LIKE " + row.id)
                        .then(_ => {
                            fetchFormations()
                        })
                }}>
                    <Delete style={{color: "#b11129"}}/>
                </IconButton>
            </Box>
        },
        {
            field: "titre",
            headerName: "Titre",
            width: 250
        }
        ,
        {
            field: "description",
            headerName: "Description",
            width: 350
        }
        ,
        {
            field: "image",
            headerName: "Image",
            width: 100
        } ,
        {
            field: "max_inscription",
            headerName: "Max inscriptions",
            width: 50
        } ,
        {
          field: "insc",
          headerName: "insc",
          renderCell: ({row}) => (<IconButton onClick={() => setFormationForListInscriptions(row)}><List/></IconButton> )
        },
        {
            field: "formateur",
            headerName: "Formateur",
            width: 200
        } ,
        {
            field: "date_creation",
            headerName: "Date création",
            width: 150
        } ,
        {
            field: "date_debut",
            headerName: "Date début",
            width: 150
        } ,
        {
            field: "date_fin",
            headerName: "Date fin",
            width: 150
        } ,
        {
            field: "etat",
            headerName: "État",
            width: 100
        }
    ]
    const [etat, setEtat] = useState("tous");
    const [showForm, setShowForm] = useState(false);
    const [rows, setRows] = useState([]);
    const [formationWillUpdate, setFormationWillUpdate] = useState({});
    const [formationForListInscriptions, setFormationForListInscriptions] = useState(null);
    useEffect(() => {
        fetchFormations()
    }, [showForm]);

    const fetchFormations = () => {
        executeQuery("SELECT * FROM formation")
            .then(rows => setRows(rows))
    }

    return (
        <>
           <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} m={1}>
               <Typography variant={"h4"}>
                   Gestion des formations
               </Typography>
               <Button
                   onClick={() => setShowForm(true)}
                   variant={"contained"} color={"primary"} startIcon={<Add/>}>Nouveau</Button>
               <RadioGroup aria-label="etat" name="etat" value={etat} onChange={e => setEtat(e.target.value)}>
                   <FormControlLabel value="tous" control={<Radio />} label="Tous" />
                   <FormControlLabel value="closed" control={<Radio />} label="Cloturées" />
                   <FormControlLabel value="open" control={<Radio />} label="Ouvertes" />
               </RadioGroup>
               <TextField  label={"Recherche"}/>
           </Box>
            <Box component={Paper} height={600}>
                <DataGrid columns={columns} rows={rows}/>
            </Box>
            {
                showForm &&
                <TilBackDrop open={showForm}>
                    <FormulaireFormation data={formationWillUpdate} onClose={() => setShowForm(false)}/>
                </TilBackDrop>
            }

            {
                formationForListInscriptions &&
                <Inscriptions onClose={() => setFormationForListInscriptions(null)} formation={formationForListInscriptions}/>
            }
        </>
    )
}