import React, {useEffect, useState} from 'react';
import TilBackDrop from "../../../components/TilBackDrop/TilBackDrop";
import {
    Box,
    Button,
    Container, FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import {API_URL, IMG_URL} from "../../../config/constants";
import {Clear, Image, Save} from "@material-ui/icons";
import placeholder from '../../../assets/images/placeholder.jpg';
import Form from "../../../components/TilDataTable/Form";
import axios from "axios";
import CrudService from "../../../service/crud.service";
export default function SliderForm({open, onClose, sliderWillUpdate, onSliderUpdated, onSliderCreated}) {
    const service = new CrudService();
    const [form, setForm] = useState({
        image: null,
        title: "",
        sub_title: "",
        title_fr:"",
        subtitle_fr:"",
        link: ""
    });
    const [mode, setMode] = useState("");
    const [products, setProducts] = useState([]);

    useEffect(() => {
            setForm(sliderWillUpdate ? sliderWillUpdate : {
                image: null,
                title: "",
                sub_title: "",
                link: ""
            })
    }, [sliderWillUpdate]);

    useEffect(() => {
        setMode("")
    }, [open])

    useEffect(() => {
        if(mode === "create")
            onSliderCreated(form)
        else if(mode === "update")
            onSliderUpdated(form)
    }, [mode])

    useEffect(() => {
        service.getRowsByTableName("products")
            .then(res => setProducts(res))
    }, [])

    const onFieldChanged = (field, value) => {
        let formWillUpdate = {...form};
        formWillUpdate[field] = value;
        setForm(formWillUpdate)
    }

    const uploadImage = e => {
        const formData = new FormData();
        formData.append('image', e.target.files[0]);
        axios.post(API_URL + 'upload-slider-image',
            formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            method: 'POST'
        })
            .then(res => res.data)
            .then(res => {
                if(res.status){
                    setForm(prevState => ({...prevState, image: IMG_URL + res.image_name}))
                }
            })
            .catch(err => console.log(err))
    }

    const onSubmit = () => {
        if(sliderWillUpdate)
            service.updateRow(form, "home_sliders")
                .then(res => {
                    if(res)
                        setMode("update")
                })
                .catch(err => console.info(err))
        else
        service.insertRow(form, 'home_sliders')
            .then(res => {
                if(res)
                    setMode("create")
            })
            .catch(err => console.info(err))
    }

    return (
        <TilBackDrop open={open}>
            <Paper>
                <Box p={3}>
                    <Box my={2}>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                              <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
                                  <Button
                                      style={{width: '100%', height: '100%', boxShadow: 'none', color: 'white'}}
                                      startIcon={<Image/>}
                                      component={'label'}
                                      variant={'contained'}
                                      color={'primary'}>
                                      Choisir image slider
                                      <input type={'file'} hidden onChange={uploadImage}/>
                                  </Button>
                              </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    InputLabelProps={{shrink: true}}
                                    value={form.title}
                                    onChange={e => onFieldChanged('title', e.target.value)}
                                    style={{width: '100%'}} label={'Titre'} variant={'outlined'}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    InputLabelProps={{shrink: true}}
                                    value={form.title_fr}
                                    onChange={e => onFieldChanged('title_fr', e.target.value)}
                                    style={{width: '100%'}} label={'Titre français'} variant={'outlined'}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    InputLabelProps={{shrink: true}}
                                    value={form.sub_title}
                                    onChange={e => onFieldChanged('sub_title', e.target.value)}
                                    style={{width: '100%'}} label={'Sous-titre'} variant={'outlined'}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    InputLabelProps={{shrink: true}}
                                    value={form.subtitle_fr}
                                    onChange={e => onFieldChanged('subtitle_fr', e.target.value)}
                                    style={{width: '100%'}} label={'Sous-titre français'} variant={'outlined'}/>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant={'outlined'}
                                             style={{width: '100%'}}
                                >
                                    <InputLabel>Product</InputLabel>
                                    <Select
                                        MenuProps={{
                                            style: {zIndex: 35001}
                                        }}
                                        value={form.link}
                                        label={"Product"}
                                        onChange={(e) => {onFieldChanged("link", e.target.value)}}
                                    >
                                        <MenuItem  value={0} disabled>Veuillez choisir</MenuItem>
                                        {
                                            products.map(p =>
                                                {
                                                    return (
                                                        <MenuItem key={p.id} value={p.id}>{p.label}</MenuItem>
                                                    )
                                                }
                                            )
                                        }

                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                    <h3>Preview</h3>
                    <Paper elevation={0}
                           style={{
                               backgroundSize: 'cover',
                               borderRadius:0,
                               backgroundImage: `url(${form.image ? (form.image) : placeholder})`,
                               height: 300,
                               width: 1300,
                               backgroundRepeat: 'no-repeat',
                               backgroundPosition: 'center center' }}>
                        <Container style={{height: '100%'}}>
                            <Grid container style={{height: '100%'}}>
                                <Grid item xs={12} md={6}>
                                    <Box display={'flex'} justifyContent={'center'}  flexDirection={'column'} height={'100%'}>
                                        <Typography style={{ fontWeight:'bolder'}} variant={'h2'}>{form.title}</Typography>
                                        <Typography style={{color: 'rgb(38,200,182)'}} variant={'h3'}>{form.sub_title}</Typography>
                                        <Button size={'large'} variant={'contained'} color={'primary'} style={{ marginTop: 16, width: 155, background: 'rgb(18, 46, 69) ', color: 'white'}}>
                                            Learn more
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Paper>
                </Box>
                <Box display={'flex'} justifyContent={'flex-end'} p={3}>
                    <Button onClick={onSubmit} size={'large'} style={{width: 150}} startIcon={<Save/>} color={'primary'} variant={'outlined'}>
                        Sauvegarder
                    </Button>
                    <Box mx={1}/>

                    <Button onClick={onClose} size={'large'} style={{width: 150}} startIcon={<Clear/>} color={'secondary'} variant={'outlined'}>
                        Fermer
                    </Button>
                </Box>
            </Paper>
        </TilBackDrop>
    );
};
