import React, {useEffect} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {createTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import Content from './layout/Content';
import Topbar from './layout/Topbar';
import Sidebar from './layout/Sidebar';
import {colors} from '../config/constants';
import {useHistory} from "react-router-dom";

const drawerWidth = 340;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Dashboard(props) {

const history = useHistory();
  // check for session
  useEffect( () => {
    (async () => {
      const user = await sessionStorage.getItem("user-admin-evo");
      if(!user)
        history.push('/admin-evo-care-tn')
    })()
  }, [])

  const { window } = props;
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [title, setTitle] = React.useState("");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: colors.primary
      }
    },
  });


  return (
    <ThemeProvider theme={theme}>
    <div className={classes.root}>
      <CssBaseline />
      <Topbar title={title} classes={classes} handleDrawerToggle={handleDrawerToggle}/>
     <Sidebar setTitle={setTitle} classes={classes} handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} window={window}/>
      <Content classes={classes}/>
    </div>
    </ThemeProvider>
  );
}



export default Dashboard;
