import React, {useEffect, useState} from 'react';
import {Box, Link, Paper, Zoom, useMediaQuery, useTheme} from "@material-ui/core";
import {ArrowRight, Language} from "@material-ui/icons";
import {IMG_URL} from "../../../../config/constants";
import AOS from 'aos';
import 'aos/dist/aos.css';
import AppText from '../../../../components/app-text';

const PartnerComponent = ({image, label, link}) => {
    const [showOverlay, setShowOverlay] = useState(false);

    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, [])
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (
            <div data-aos='flip-left'>
                <Box style={{cursor: 'pointer'}} position={'relative'} onMouseEnter={() => setShowOverlay(true)} onMouseLeave={() => setShowOverlay(false)}>
                    <Paper>
                        <Box textAlign={'center'}  height={120} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <img width={!isMobile ? '70%' : '48%'} src={IMG_URL+image} alt="d"/>
                        </Box>
                        {/*<Typography variant={'h5'} style={{textAlign: 'center'}}>*/}
                        {/*    {label}*/}
                        {/*</Typography>*/}
                    </Paper>


                    <Zoom in={showOverlay} timeout={200}>
                        <Box onClick={() => window.open(link,'_blank')} borderRadius={4} display={'flex'} justifyContent={'end'} alignItems={'end'} top={0} height={'100%'} width={'100%'} position={'absolute'}
                             >
                            <Link style={{display:"flex", alignItems: "center", fontSize: 12}}><span><AppText text={"partner-site"}/></span> <ArrowRight/></Link>
                        </Box>
                    </Zoom>

                </Box>

            </div>

    );
}
export default PartnerComponent;
