import React, {useEffect, Suspense} from 'react';
import Navbar from "./layout/navbar";
import Partners from "./pages/LandingPage/partners";
import Species from "./pages/LandingPage/species";
import {Box, MuiThemeProvider, Paper} from "@material-ui/core";
import {createTheme} from "@material-ui/core/styles";
import Contact from "./pages/LandingPage/contact";
import Footer from "./layout/footer";
import {KeyboardArrowUp} from "@material-ui/icons";
import TopBar from "./layout/topbar";
import Products from "./pages/LandingPage/products";
import {useParams} from "react-router-dom";
import {About} from "./pages/LandingPage/about";
import "./globals.css";
import LoadingComponent from "../components/loading";
import Formations from "./pages/LandingPage/formation";
import References from './pages/LandingPage/references';
const Hero = React.lazy(() => import("./pages/LandingPage/hero"))

const IndexV2 = () => {
    const {page, category, subCategory} = useParams();
    useEffect(() => {
        if (!document.querySelector('#header'))
            return;
        window.onscroll = function () {
            myFunction()
        };

        let header = document.getElementById("header");
        let sticky = header.offsetTop;

        function myFunction() {
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        }
    }, [document.querySelector('#header')])

    const theme = createTheme(theme => ({
        palette: {
            primary: {
                main: '#fff'
            }
        }
    }))

    const scrollerTop = (
        <Box height={80} width={80} position={'absolute'} bottom={12} right={12}>
            <Paper elevation={5}>
                <KeyboardArrowUp/>
            </Paper>
        </Box>
    )

    return (
        <MuiThemeProvider theme={theme}>
            <main>
                <TopBar/>
                <Navbar target={category}/>
                {
                    !page ?
                        <>
                            <Suspense fallback={<LoadingComponent/>}>
                                <Hero/>
                            </Suspense>
                            <Partners/>
                            <About/>
                            <References/>
                            {/* <Species/> */}
                            {/* <Formations/> */}
                            <Contact/>
                        </>
                        :
                        <Products category={category} subCategory={subCategory}/>
                }
                <Footer/>
                {/*{scrollerTop}*/}
            </main>
        </MuiThemeProvider>
    )
}

export default IndexV2;
