import * as React from 'react';

import {LockOutlined} from '@material-ui/icons';
import {
    Avatar,
    Box,
    Button,
    Container,
    createTheme,
    CssBaseline,
    Link,
    MuiThemeProvider,
    TextField,
    Typography
} from '@material-ui/core';
import {useHistory} from 'react-router-dom'
import {API_URL} from "../config/constants";
import axios from "axios";

function Copyright(props) {
  return (
    <Typography variant="body2" color="secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" to={'/'}>
        TechInLife
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
    const history = useHistory();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    const form = {
      login: data.get('login'),
      pwd: data.get('pwd'),
    };
      axios.post(API_URL + `authentication`,
          form,
          {
              headers: {
                  'Content-Type': 'application/json'
              },
              method: 'POST'
          })
          .then(res => {
              if(res.data.status){
                  sessionStorage.setItem('user-admin-evo', {});
                  history.push('/admin-module')
              }
          })
          .catch(err => console.log(err))
  };


  return (
    <MuiThemeProvider theme={theme}>
      <Container style={{height: '100vh'}} component="main" maxWidth="xs">
        <CssBaseline />
        <Box
            height={'80%'}
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
            display={'flex'}
            justifyContent={'center'}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Se Connecter
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                  <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="login"
                      autoComplete="email"
                      autoFocus
                  />
                  <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="pwd"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                  />

                  <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      style={{marginTop: 16}}
                  >
                      Valider
                  </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </MuiThemeProvider>
  );
}
