import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    'TilBackdrop-root':{
        position: 'fixed',
        top: 0,
        left:0,
        height: '100vh',
        width: '100%',
        display:'flex',
        justifyContent:'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,.16)',
        zIndex: 9999
    }
}))
