import {AppBar, Button, IconButton, Toolbar, Typography} from '@material-ui/core';
import {ExitToApp, Menu} from '@material-ui/icons';
import React from 'react';
import {useHistory} from "react-router-dom";

const Topbar = ({classes, handleDrawerToggle, title}) => {
    const history = useHistory();
    return (
        <AppBar position="fixed" className={classes.appBar}>
        <Toolbar style={{
            display: 'flex',
            justifyContent: 'space-between'
        }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
           <Button onClick={() => {
               sessionStorage.clear();
               history.push('/admin-evo-care-tn')
           }} endIcon={<ExitToApp/>}>Se deconnecter</Button>
        </Toolbar>

      </AppBar>
    );
}

export default Topbar;
