import {
  Backdrop,
  Box,
  Button, FormControl, FormControlLabel, InputLabel,
  makeStyles, MenuItem,
  Paper, Select,
  Switch,
  TextField,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import frToEnMapper from "../../config/frToEnMapper";
import TilBackDrop from "../TilBackDrop/TilBackDrop";
import {dateToInput} from "../../config/dateUtils";

const Form = ({
  open,
  handleClose,
  fields,
  service,
  row = {},
  dbTableName,
  onRowCreated,
  onRowUpdated,
    staticValues = [],
    relations = [],
    uploadables = [],
    columns,
    mode,
    rows,
    switchers = []
}) => {
  const classes = useStyles();
  const [form, setForm] = useState({});
  useEffect(() => {
    let rowWillSet = {...row}
    if(relations.length>0){
      relations.forEach(r => {
        rowWillSet[r.joinOn] = r.rows.find(row1 => row1.label === row[r.joinOn])?.id
      })
    }
    setForm(rowWillSet);
  }, [row, relations]);

  const handleFormFieldChanged = (field, value) => {
    let formWillUpdate = { ...form };
    formWillUpdate[field] = value;
    setForm(formWillUpdate);
  };

  const onSubmit = (e) => {
    let formWillInsert = {...form};
    console.log(form)
    if(mode === "create")
      formWillInsert.id = Math.max.apply(Math, rows.map(function(o) { return o.id; })) + 1;
    e.preventDefault();
    mode === "create"
      ? service
          .insertRow(formWillInsert)
          .then((res) => {
            if (res) onRowCreated(formWillInsert);
            else alert(res);
          })
          .catch((err) => alert(err))
      : service
          .updateRow(form)
          .then((res) => {
            if (res) onRowUpdated(form);
            else alert(res);
          })
          .catch((err) => alert(err));
  };

  if (Object.keys(form).length === 0) return <>d</>;

  return (
    <TilBackDrop open={open}>

      <Paper className={classes.root}>

        <h3>
          {mode === "create" ? "Créer " : "Modifier"} {" "}
          {frToEnMapper(dbTableName)}
        </h3>
        {open && (
          <form className={classes.form} onSubmit={onSubmit}>
            {fields.map((field) => {
              const staticValueIndex = staticValues.findIndex(staticValue => staticValue.column === field);

              if(staticValueIndex !== -1)
              {
                const column = staticValues[staticValueIndex].column;
                return (<FormControl key={field}  variant={'outlined'} className={classes.select}>
                  <InputLabel>{column}</InputLabel>
                  <Select
                      MenuProps={{
                        style: {zIndex: 35001}
                      }}
                      value={form[column] || 0}
                      label={column}
                      onChange={(e) => {handleFormFieldChanged(column, e.target.value)}}
                  >
                    <MenuItem  value={0} disabled>Veuillez choisir</MenuItem>
                    {
                      staticValues[staticValueIndex].values.map(value =>
                          {
                            return (
                                <MenuItem key={value} value={value}>{value}</MenuItem>
                            )
                          }
                      )
                    }

                  </Select>
                </FormControl>)
              }
              if(relations.findIndex(relation => relation.joinOn === field) !== -1){
                return (
                    <FormControl key={field}  variant={'outlined'} className={classes.select}>
                      <InputLabel>{frToEnMapper(field)}</InputLabel>
                      {
                        console.log("relation: ", form[field])
                      }
                      <Select
                          MenuProps={{
                            style: {zIndex: 35001}
                          }}
                          value={
                            // relations.find(relation => relation.joinOn === field).rows.find(r => r.label === form[field]) ? relations.find(relation => relation.joinOn === field).rows.find(r => r.label === form[field]).id : 0
                           form[field]
                          }
                          label={field}
                          onChange={(e) => {handleFormFieldChanged(field, e.target.value)}}
                      >
                        <MenuItem  value={0} disabled>Veuillez choisir</MenuItem>
                        {
                          relations.find(relation => relation.joinOn === field).rows.map(row =>
                              {
                                return (
                                    <MenuItem key={row.id} value={row.id}>{row.label}</MenuItem>
                                )
                              }
                          )
                        }

                      </Select>
                    </FormControl>
                )
              }
              if(uploadables.findIndex(uploadable => uploadable.field === field) !== -1){
                return (
                <div/>
                    )
              }
             
             const column = columns.find(column => column.Field === field);
              if((column.Type.includes('longtext') || column.Type.includes('date')) && field !== "id"){
                return (
                    column.Type === 'date' ?
                        <TextField
                            disabled
                            defaultValue={dateToInput(new Date())}
                            key={field}
                            variant="outlined"
                            label={frToEnMapper(field)}
                            type={'date'}
                            InputLabelProps={{shrink:true}}
                        />
                        :
                        <TextField
                            value={form[field]}
                            onChange={(e) =>
                                handleFormFieldChanged(field, e.target.value)
                            }
                            key={field}
                            variant="outlined"
                            label={frToEnMapper(field)}
                            multiline
                            rows={5}
                        />
                )
              }
              if (field !== "id")
                return (
                  <TextField
                    value={form[field]}
                    onChange={(e) =>
                      handleFormFieldChanged(field, e.target.value)
                    }
                    key={field}
                    variant="outlined"
                    label={frToEnMapper(field)}
                  />
                );

              return (<span/>)
            })}

            <Box
              display="flex"
              borderTop={1}
              mt={1}
              pt={1}
              justifyContent="flex-end"
            >
              <Button
                size="large"
                color="primary"
                variant="outlined"
                type="submit"
              >
                Valider
              </Button>
              <Box m={1}/>
              <Button
                size="large"
                color="secondary"
                variant="outlined"
                onClick={handleClose}
              >
                Fermer
              </Button>
            </Box>
          </form>
        )}
      </Paper>
    </TilBackDrop>
  );
};

export default Form;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 24,
    width: "40%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    "& .MuiFormControl-root": {
      marginBottom: theme.spacing(1),
    },
  },
  select:{
    '& *':{
      zIndex: 2001
    }
  }
}));
