import React, {useEffect, useState} from 'react';
import {Box, Button, Container, Grid, Paper, Typography} from "@material-ui/core";
import {Add, Delete} from "@material-ui/icons";
import SliderForm from "./SliderForm";
import {IMG_URL} from "../../../config/constants";
import placeholder from "../../../assets/images/placeholder.jpg";
import CrudService from "../../../service/crud.service";

const HomeSliders = () => {
    const service = new CrudService();
    const [openForm, setOpenForm] = useState(false);
    const [sliders, setSliders] = useState([]);
    const [sliderWillUpdate, setSliderWillUpdate] = useState(null);
    useEffect(() => {
        service.getRowsByTableName('home_sliders')
            .then(res => setSliders(res))
    }, [])

    const deleteSlider = id => {
        service.deleteRow(id, 'home_sliders')
            .then(res => {
                if(res){
                    setSliders(sliders.filter(s => s.id !== id))
                }
            })
    }

    const onUpdateClicked = row => {
        setSliderWillUpdate(row)
    }

    const onSliderAdded = slider => {
        setSliders([...sliders, slider]);
        setOpenForm(false)
        setSliderWillUpdate(null)
    }

    const onSliderUpdated = slider => {
        let slidersWillUpdate = [...sliders];
        const index = slidersWillUpdate.findIndex(s => s.id === slider.id);
        slidersWillUpdate[index] = slider;
        setSliders(slidersWillUpdate);
        setOpenForm(false)
        setSliderWillUpdate(null)
    }

    useEffect(() => {
        if(sliderWillUpdate)
            setOpenForm(true)
    }, [sliderWillUpdate])


    return (
        <Box p={3}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <h1>Gestion des sliders d'acceuil</h1>
                <Button onClick={() => {
                    setSliderWillUpdate(null)
                    setOpenForm(true)
                }} variant={'outlined'} color={'success'}>
                    <Add/>
                </Button>
            </Box>


            <>
                {
                    sliders.map(s => (
                        <Paper key={s.id} style={{marginTop: 16, width: 'fit-content'}}>
                            <Paper elevation={0}  style={{ position: 'relative',backgroundSize: 'cover',borderRadius:0, backgroundImage: `url(${s.image})`, height: 300, width: 800, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center' }}>
                                <Box position={'absolute'} style={{top: 8, right: 8}}>
                                    <Button onClick={() => onUpdateClicked(s)} startIcon={<Delete/>} style={{backgroundColor: '#fcd456'}} variant={'outlined'}>
                                        Modifier
                                    </Button>
                                    <Button onClick={() => deleteSlider(s.id)} startIcon={<Delete/>} color={'secondary'} variant={'outlined'}>
                                        Supprimer
                                    </Button>
                                </Box>
                                <Container style={{height: '100%'}}>
                                    <Grid container style={{height: '100%'}}>
                                        <Grid item xs={12} md={6}>
                                            <Box display={'flex'} justifyContent={'center'}  flexDirection={'column'} height={'100%'}>
                                                <Typography style={{ fontWeight:'bolder'}} variant={'h2'}>{s.title}</Typography>
                                                <Typography style={{color: 'rgb(38,200,182)'}} variant={'h3'}>{s.sub_title}</Typography>
                                                <Button size={'large'} variant={'contained'} color={'primary'} style={{ marginTop: 16, width: 155, background: 'rgb(18, 46, 69) ', color: 'white'}}>
                                                    Learn more
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Paper>
                        </Paper>
                    ))
                }
            </>

            <SliderForm
                onSliderCreated={onSliderAdded}
                onSliderUpdated={onSliderUpdated}
                sliderWillUpdate={sliderWillUpdate}
                open={openForm}
                onClose={() => {
                setOpenForm(false)
                setSliderWillUpdate(null)
            }}/>
        </Box>
    )
}

export default HomeSliders;
